import store from '@/store'; 

const confirmationService = {
   async show(msg){
    const confirmed = await store.dispatch(
        "showConfirmModal",
        msg
      );
      return confirmed;
    }
}

export default confirmationService;