import apiService from "./apiService";

const dashboardService = {
    async getRecentLogs() {
        try {
            return (await apiService.get(`u/getrecentlogs`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getVoterSummary(id) {
        try {
            const resp = (await apiService.get(`d/getvotersummary/${id}`, {}, true)).data;
            if (resp === null || resp.totalNonSupporters === null) {
                return { totalSupporters: 0, totalNonSupporters: 0, totalRawVoters: 0, expectedTurnout: 0.0 };
            }
            return resp;
        }
        catch {
            return { totalSupporters: 0, totalNonSupporters: 0, totalRawVoters: 0, expectedTurnout: 0.0 };
        }
    },
    async getScanningSummary(id) {
        try {
            const resp = (await apiService.get(`d/getscanningsummary/${id}`, {}, true)).data;
            if (resp === null) {
                return {
                    pending: 0,
                    partially: 0,
                    completed: 0,
                };
            }
            return resp;
        }
        catch {
            return {
                pending: 0,
                partially: 0,
                completed: 0,
            };
        }
    },
    async getDashboardBreakdown(id, page) {
        try {
            return (await apiService.get(`d/getbrgybreakdown/${id}/${page}`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getDashboardVoterByStatus(id, action, page) {
        try {
            return (await apiService.get(`d/getvoterbystatus/${id}/${action}/${page}`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getDashboardVoterByType(id, action, page) {
        try {
            return (await apiService.get(`d/getvoterbytype/${id}/${action}/${page}`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getLatestChartMovement(cityid, timerange) {
        try {
            return (await apiService.get(`d/getvotermc/${cityid}/${timerange}`, {}, true)).data;
        }
        catch {
            return [];
        }
    }
};

export default dashboardService;