<template>
  <div class="searchable-dropdown">
    <input
      type="text"
      class="form-select"
      id="ipselect"
      @click="showDropdown = true"
      v-model="searchQuery"
      @focus="showDropdown = true"
      @blur="hideDropdown"
      @keydown="showDropdown = true"
      @input="filterItems"
      placeholder="Enter keyword"
      @keydown.down.prevent="highlightNextItem"
      @keydown.up.prevent="highlightPreviousItem"
      @keydown.enter.prevent="selectHighlightedItem"
    />
    <ul
      v-if="showDropdown"
      :style="{ width: inputWidth + 'px' }"
      class="dropdown-menu show"
    >
      <li
        v-for="(item, index) in filteredItems"
        :key="item.id"
        class="dropdown-item"
        :class="{ highlighted: index === highlightedIndex }"
        @mousedown.prevent="selectItem(item)"
      >
        {{ item.name_voter }}
      </li>
    </ul>
  </div>
</template>

<script>
import householdService from "@/services/householdService";
import { ref, watch } from "vue";
import _ from "lodash";

export default {
  props: {
    cityid: Number,
    brgyid: Number,
    isClearItemsInvoked: Boolean,
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputWidth = ref(0);
    const searchQuery = ref(props.value ? props.value.name : "");
    const showDropdown = ref(false);
    const filteredItems = ref(props.items);
    const highlightedIndex = ref(-1);
    const selectedItemName = ref("");
    /* const filterItems = () => {
      const query = searchQuery.value.toLowerCase();
      filteredItems.value = props.items.filter((item) =>
        item.name_voter.toLowerCase().includes(query)
      );
      highlightedIndex.value = -1;
    }; */

    const filterItems = _.debounce(async () => {
      if (searchQuery.value.length < 1) return;
      const query = searchQuery.value.toLowerCase();
      filteredItems.value = await householdService.getNoHouseholdVoters(
        props.cityid,
        props.brgyid,
        query
      );
      highlightedIndex.value = -1;
    }, 300); // Debounce the filtering function to reduce API requests frequency

    const selectItem = (item) => {
      searchQuery.value = item.name_voter; // Update the searchQuery with the selected item's name
      selectedItemName.value = item.name_voter;
      showDropdown.value = false; // Hide dropdown after selection
      emit("update:modelValue", item); // Emit the ID of the selected item to the parent component
      searchQuery.value = "";
    };

    const hideDropdown = () => {
      setTimeout(() => {
        showDropdown.value = false;
      }, 100);
      if (searchQuery.value.toLowerCase() !== selectedItemName.value.toLowerCase()) {
        emit("update:modelValue", null);
      }
    };

    const highlightNextItem = () => {
      if (highlightedIndex.value < filteredItems.value.length - 1) {
        highlightedIndex.value++;
      }
    };

    const highlightPreviousItem = () => {
      if (highlightedIndex.value > -1) {
        highlightedIndex.value--;
      }
    };

    const selectHighlightedItem = () => {
      if (
        highlightedIndex.value >= 0 &&
        highlightedIndex.value < filteredItems.value.length
      ) {
        selectItem(filteredItems.value[highlightedIndex.value]);
      }
    };

    watch(searchQuery, (newQuery) => {
      if (!newQuery) {
        filteredItems.value = props.items;
      } else {
        filterItems();
      }
    });

    watch(
      () => props.isClearItemsInvoked,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal === true) {
          filteredItems.value = [];
          searchQuery.value = "";
        }
      }
    );

    watch(showDropdown, (newVal) => {
      if (newVal) {
        const inputElement = document.getElementById("ipselect");
        inputWidth.value = inputElement.offsetWidth;
      }
    });

    return {
      searchQuery,
      showDropdown,
      filteredItems,
      selectItem,
      hideDropdown,
      highlightedIndex,
      highlightNextItem,
      highlightPreviousItem,
      selectHighlightedItem,
      inputWidth,
    };
  },
};
</script>
<style scoped>
.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li.highlighted {
  background: #f0f0f0;
}
</style>
