<template>
  <div class="file-upload-container shadow rounded py-3">
    <h3 class="mb-3"><i class="bi bi-upload"></i> Add-on Voters Upload</h3>
    <div class="form-group text-start mx-3">
      <label>Select City/Municipality</label>
      <select @change="handleCityChange" class="form-select" v-model="cityId">
        <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
          {{ city.name_mun }}
        </option>
      </select>
    </div>

    <input
      ref="fileInput"
      type="file"
      @change="handleFileUpload"
      accept=".xlsx, .xls"
      style="display: none"
    />

    <!-- Custom element that triggers the file input -->
    <div
      style="cursor: pointer"
      v-if="!isProcessing"
      class="custom-upload-button"
      @click="triggerFileUpload"
    >
      <i class="bi bi-cloud-upload"></i>
      <p>Upload Excel File</p>
    </div>

    <div v-if="errors.length" class="errors-container">
      <p>Please correct the following errors:</p>
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
    </div>

    <div v-if="isProcessing" class="progress-container">
      <div class="icon-container">
        <i class="bi bi-file-earmark-spreadsheet"></i>
        <div
          class="progress-circle"
          :style="{ strokeDasharray: progressDasharray }"
        ></div>
      </div>
      <p>Uploading {{ uploadedRows }} of {{ totalRows }} rows...</p>
    </div>

    <div
      v-if="successMessage"
      class="alert alert-success mx-2 d-flex justify-content-center align-items-center"
    >
      <p>{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import voterService from "@/services/voterService";
import { ref, onMounted } from "vue";
import * as XLSX from "xlsx";

export default {
  setup() {
    const errors = ref([]);
    const successMessage = ref("");
    const isProcessing = ref(false);
    const uploadedRows = ref(0);
    const totalRows = ref(0);
    const progressDasharray = ref("0 100");
    const fileInput = ref(null);
    const cities = ref([]);
    const cityId = ref(0);
    const brgys = ref([]);
    const handleCityChange = async () => {
      brgys.value = await areaService.getBrgys(cityId.value, true);
    };
    const triggerFileUpload = () => {
      if (cityId.value === 0) {
        toastService.error("Please select city or municipality first");
        return;
      }
      fileInput.value.click();
    };

    const handleFileUpload = async (event) => {
      successMessage.value = null;
      uploadedRows.value = 0;
      const file = event.target.files[0];
      if (!file) return;
      spinnerService.show();
      isProcessing.value = true;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        const headers = worksheet[0];
        const rows = worksheet.slice(1);
        totalRows.value = rows.length;
        spinnerService.hide();

        // Validate required fields
        //this.errors = this.requiredFields.filter((field) => !headers.includes(field));
        if (errors.value.length > 0) {
          isProcessing.value = false;
          return;
        }

        const dataToSend = rows.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            if (
              header === "precinct_voter" ||
              header === "birth_voter" ||
              header === "sitio_voter" ||
              header === "gender_voter"
            ) {
              obj[header] = String(row[index]); // Convert to string
            } else {
              obj[header] = row[index]; // Use the original value for other headers
            }
          });
          obj["side_voter"] = false;
          obj["cityid_voter"] = cityId.value;
          // obj["brgyid_voter"] = getBrgyId(obj["brgyid"]);
          // if (obj["brgyid_voter"] === 0) {
          //  console.log(obj["brgy_voter"]);
          // }
          return obj;
        });

        // Process in batches of 300 rows
        const batchSize = 50;
        for (let i = 0; i < dataToSend.length; i += batchSize) {
          const batch = dataToSend.slice(i, i + batchSize);
          uploadedRows.value += batch.length;
          await sendBatchToApi(batch);
          updateProgress();
        }

        successMessage.value = "Data processed and sent successfully!";
        isProcessing.value = false;
      };

      reader.readAsArrayBuffer(file);
    };
    const getBrgyId = (brgyname) => {
      const brgy = brgys.value.find((x) => x.name_brgy.trim() == brgyname.trim());
      return brgy ? brgy.id_brgy : 0;
    };
    // eslint-disable-next-line no-unused-vars
    const sendBatchToApi = async (batch) => {
      const maxRetries = 15;
      let attempts = 0;
      let success = false;

      while (attempts < maxRetries && !success) {
        try {
          console.log(`Attempt ${attempts + 1} to send batch:`, batch);
          const response = await voterService.import(batch);
          if (!response.success) throw "Failed";
          // Handle response as needed

          // If the request is successful, set success to true to break out of the loop
          success = true;
          console.log("Batch sent successfully");
        } catch (error) {
          attempts += 1;
          console.error(`Error sending batch to API (Attempt ${attempts}):`, error);

          // If the maximum number of retries is reached, notify the user
          if (attempts === maxRetries) {
            console.error("Max retries reached. Unable to send batch to API.");
            // You can add additional user notification logic here if needed
          } else {
            // Optionally, wait a bit before retrying (e.g., 1 second delay)
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      }
    };

    const updateProgress = () => {
      const progress = (uploadedRows.value / totalRows.value) * 100;
      const dashArrayValue = `${progress} ${100 - progress}`;
      progressDasharray.value = dashArrayValue;
    };

    onMounted(async () => {
      cities.value = await areaService.getMunicipalities();
    });

    return {
      errors,
      successMessage,
      isProcessing,
      uploadedRows,
      totalRows,
      handleFileUpload,
      progressDasharray,
      fileInput,
      triggerFileUpload,
      cities,
      cityId,
      handleCityChange,
      getBrgyId,
    };
  },
};
</script>

<style scoped>
.file-upload-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.errors-container,
.success-container {
  margin-top: 20px;
}

.progress-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.bi-file-earmark-spreadsheet {
  font-size: 50px;
  color: #28a745;
}

.progress-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top: 5px solid #28a745;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
