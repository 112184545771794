<template>
  <div class="text-end">
    <select
      style="width: 300px"
      v-model="selectedTimeRange"
      @change="getLatestChartMovement"
    >
      <option value="today">Today</option>
      <option value="yesterday">Yesterday</option>
      <option value="thisweek">This Week</option>
      <option value="lastweek">Last Week</option>
      <option value="thismonth">This Month</option>
      <option value="lastmonth">Last Month</option>
      <option value="thisyear">This Year</option>
      <option value="lastyear">Last Year</option>
    </select>
  </div>

  <div class="chart-container">
    <canvas id="mchart"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, onBeforeUnmount } from "vue";
import { Chart, registerables } from "chart.js";
import dashboardService from "@/services/dashboardService";

// Register all Chart.js components
Chart.register(...registerables);

export default {
  props: {
    id: { type: Number, default: () => 0 },
  },
  setup(props) {
    const chartInstance = ref(null);
    const selectedTimeRange = ref("today");

    const updateBarChart = (labels, data) => {
      if (chartInstance.value) {
        try {
          chartInstance.value.destroy();
        } catch (err) {
          console.error("Error destroying chartInstance:", err);
        }
      }
      const canvas = document.getElementById("mchart");
      clearCanvas(canvas); // Call this function before drawing the chart
      setTimeout(() => {
        const ctx = canvas.getContext("2d");
        if (ctx) {
          try {
            chartInstance.value = new Chart(ctx, {
              type: "bar",
              data: {
                labels: labels,
                datasets: [
                  {
                    label: "Supporters",
                    data: data,
                    backgroundColor: "rgba(150, 75, 75, 0.5)",
                    borderColor: "rgba(150, 75, 75, 1)",
                    borderWidth: 1,
                  },
                ],
              },
              options: {
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              },
            });
          } catch (err) {
            console.log("Render Error", err);
          }
        } else {
          console.log("Canvas element not found. Retrying...");
          setTimeout(() => updateBarChart(labels, data), 100); // Retry after a short delay
        }
      }, 300);
    };

    const getLatestChartMovement = async () => {
      try {
        const resp = await dashboardService.getLatestChartMovement(
          props.id,
          selectedTimeRange.value
        );

        const labels = resp.map((item) => item.label);
        const data = resp.map((item) => item.data);

        await nextTick();

        // Add a small delay before updating the chart
        setTimeout(() => {
          updateBarChart(
            JSON.parse(JSON.stringify(labels)), // Break reactivity
            JSON.parse(JSON.stringify(data)) // Break reactivity
          );
        }, 100); // Adjust this delay as needed
      } catch (error) {
        console.error("Error fetching chart data", error);
      }
    };

    const clearCanvas = (canvas) => {
      // Ensure the canvas is valid
      if (!canvas) {
        console.error("Canvas element is null or undefined.");
        return;
      }

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Failed to get 2D context.");
        return;
      }

      ctx.save();
      ctx.resetTransform();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.restore();
    };

    onMounted(() => {
      // Fetch initial data for the chart
      setTimeout(() => {
        getLatestChartMovement(); // Fetch new data
      }, 100);
    });

    onBeforeUnmount(() => {
      if (chartInstance.value) {
        // console.log("Cleaned-up");
        chartInstance.value.destroy(); // Clean up chart instance
      }
    });

    return {
      selectedTimeRange,
      getLatestChartMovement,
      updateBarChart,
    };
  },
};
</script>

<style scoped>
.chart-container {
  max-width: 100%;
  margin: 0 auto;
}
</style>
