<template>
    <div :class="['modal', 'fade', { show: show, active: show }]" v-show="show" id="fullScreenModal" tabindex="-1"
        role="dialog" aria-labelledby="fullScreenModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-between">
                    <div class="modal-title">
                        {{ title }}
                    </div>
                    <button @click="close" type="button" class="btn btn-danger close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="modalBody" class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            default() {
                return "Modal Title"
            }
        }
    }
    ,
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        }

        return {
            close
        }
    }
}
</script>
<style scoped>
.show {
    display: block;
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.9s ease;
    /* Transition effect */
}

.show.active {
    opacity: 1;
}
</style>