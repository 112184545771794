<template>
  <div class="d-flex align-items-center justify-content-center min-vh-100">
    <form
      class="p-4 bg-light rounded shadow-sm"
      style="min-width: 350px; max-width: 100%"
      @submit.prevent="handleSubmit"
    >
      <div class="text-center mb-4" style="margin-top: -105px">
        <router-link to="/">
          <img src="/favicon.ico" class="rounded-circle" height="150" width="150" />
        </router-link>
      </div>
      <h2 class="text-center mb-4">Login</h2>

      <!-- Username field -->
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input
          type="text"
          id="username"
          v-model="username"
          class="form-control"
          required
        />
      </div>

      <!-- Password field with toggle visibility -->
      <div class="mb-3 form-group">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input
            :type="passwordFieldType"
            id="password"
            v-model="password"
            class="form-control"
            required
          />
          <!-- Toggle password visibility icon -->
          <span
            :class="
              passwordFieldType === 'password' ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
            "
            @click="togglePasswordVisibility"
            class="input-group-text"
            style="cursor: pointer"
          ></span>
        </div>
      </div>

      <div class="col-12 mb-2 px-0">
        <div v-if="errmsg" class="alert alert-danger mx-0">{{ errmsg }}</div>
      </div>

      <!-- Submit button -->
      <button type="submit" class="btn btn-danger w-100 mb-2">Login</button>
      <button
        type="button"
        @click.prevent="handleCancelClick"
        class="btn btn-secondary w-100"
      >
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default {
  name: "LoginPage",
  setup() {
    const username = ref("");
    const password = ref("");
    const passwordFieldType = ref("password"); // Track whether password is visible or hidden
    const store = useStore();
    const errmsg = ref("");
    const returnURL = router.currentRoute.value.query.return_url || "/";
    const isSubmitting = ref(false);
    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    const handleSubmit = async () => {
      isSubmitting.value = true;
      try {
        errmsg.value = "";
        await store.dispatch("auth/login", {
          username: username.value,
          password: password.value,
        });
        router.replace(returnURL);
      } catch (error) {
        console.error("Login error:", error);
        errmsg.value = error.error ? error.error.message : "Server is unreachable.";
      } finally {
        isSubmitting.value = false;
      }
    };

    const handleCancelClick = () => {
      router.replace("/");
    };

    return {
      username,
      password,
      passwordFieldType,
      togglePasswordVisibility,
      handleSubmit,
      handleCancelClick,
      errmsg,
      isSubmitting,
    };
  },
};
</script>

<style scoped>
/* Ensure the form has a minimum and maximum width for responsiveness */
@media (max-width: 576px) {
  form {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
