<template>
  <h3 class="mt-1"><i class="bi bi-buildings-fill me-1"></i>City Settings</h3>
  <CitiesTable />
</template>
<script>
import CitiesTable from "@/components/areas/CitiesTable.vue";

export default {
  components: {
    CitiesTable,
  },
};
</script>
