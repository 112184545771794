import * as signalR from '@microsoft/signalr';
import store from '@/store';
import { apiConfig } from '@/utils/config';

class SignalRService {
  constructor() {
    this.connection = null;
    this.reconnectInterval = 5000; // Time between reconnection attempts in milliseconds
    this.eventHandlers = {}; // Store event handlers
    this.requestQueue = []; // Queue for requests made before connection
  }

  async initializeConnection() {
    if (!this.isAuthenticated()) {
      console.warn("Cannot initialize connection: User is not authenticated.");
      return;
    }

    this.createConnection();

    try {
      await this.connection.start();
      console.log("SignalR connection established");
      this.reSubscribeToEvents();
      this.processQueue(); // Process queued requests
    } catch (error) {
      console.error("SignalR connection error:", error);
      this.handleReconnection();
    }

    this.connection.onclose(() => {
      console.log('SignalR connection closed.');
      this.handleReconnection();
    });

    this.connection.onreconnected(() => {
      console.log('SignalR connection reestablished.');
      this.reSubscribeToEvents();
      this.processQueue(); // Process queued requests
    });
  }


  createConnection() {
    const currentUser = store.getters['auth/currentUser'];
    if (!currentUser) return;

    const accessToken = currentUser.accessToken;
    const baseURL = apiConfig.baseUrl.replace('/v1/', '');
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseURL}/notificationHub`, {
        accessTokenFactory: () => accessToken,
      })
      .build();
  }

  handleReconnection() {
    if (this.isAuthenticated()) {
      setTimeout(() => {
        this.createConnection();
        this.initializeConnection();
      }, this.reconnectInterval);
    }
  }

  isAuthenticated() {
    const currentUser = store.getters['auth/currentUser'];
    return currentUser && !!currentUser.accessToken;
  }

  async getPaginatedUsers(pageNumber, pageSize) {
    if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
      try {
        return await this.connection.invoke('GetPaginatedUsers', pageNumber, pageSize);
      } catch (error) {
        console.error('Error while fetching paginated users:', error);
        return { users: [], totalCount: 0 };
      }
    } else {
      console.warn('Connection not established. Queuing request.');
      return new Promise((resolve) => {
        this.requestQueue.push(() => {
          this.getPaginatedUsers(pageNumber, pageSize).then(resolve);
        });
      });
    }
  }

  processQueue() {
    while (this.requestQueue.length > 0) {
      const request = this.requestQueue.shift();
      request();
    }
  }

  subscribeToEvent(eventName, callback) {
    if (this.connection) {
      if (!this.eventHandlers[eventName]) {
        this.eventHandlers[eventName] = [];
      }
      this.eventHandlers[eventName].push(callback);
      this.connection.on(eventName, callback);
    } else {
      console.error('Connection is not established.');
    }
  }

  reSubscribeToEvents() {
    for (const [eventName, handlers] of Object.entries(this.eventHandlers)) {
      handlers.forEach(handler => {
        this.connection.on(eventName, handler);
      });
    }
  }
}

export default new SignalRService();
