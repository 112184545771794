import CryptoJS from 'crypto-js';


const ENCRYPTION_KEY = 'e3e3d9d3d25b7d5a1b4e1b1f91c0e7e8f9c6b6b6e8f4b92d0d3e0e6d1a8b752s';
const keyBytes = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY);

export function encryptData(data) {
    if (!data)
        return null;
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Return the IV and encrypted data, properly formatted
    return iv.toString() + ':' + encrypted.toString();
}


export function decryptData(encryptedData) {
    // Ensure the encrypted data is properly split into IV and ciphertext
    if (!encryptedData) {
        return null;
    }
    const parts = encryptedData.split(':');
    if (parts.length !== 2) {
        throw new Error('Invalid encrypted data format');
    }

    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const encrypted = parts[1];

    const decrypted = CryptoJS.AES.decrypt(encrypted, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}