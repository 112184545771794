<template>
  <div class="container mt-3">
    <h2 class="text-center">Persons Information</h2>

    <table class="table table-striped align-middle">
      <thead>
        <tr>
          <th>Household</th>
          <th>Name</th>
          <th></th>
          <!-- Empty column for the button -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(person, index) in persons" :key="index">
          <td>{{ person.name_hh }}</td>
          <td>{{ person.name_voter }}</td>
          <td class="text-end">
            <button
              v-if="person.lat_hh !== 0"
              @click="locateLocation(person)"
              class="btn btn-primary"
            >
              <i class="bi bi-geo-alt"></i> Locate
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    persons: Object,
  },
  emits: ["locate-person"],
  setup(props, { emit }) {
    const locateLocation = (person) => {
      emit("locate-person", {
        lat_hh: person.lat_hh,
        lng_hh: person.lng_hh,
        syspk_voter: person.syspk_voter,
      });
    };
    return {
      locateLocation,
    };
  },
};
</script>
