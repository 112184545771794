<template>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Name</th>
            <th>District</th>
          </tr>
          <tr>
            <th>
              <input class="form-control" v-model="searchModel.name_mun" />
            </th>
            <th>
              <select class="form-select" v-model="searchModel.districtId_brgy">
                <option value="0"></option>
                <option value="0">IV District</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="city in cities" :key="city.id_mun">
            <td>
              <a
                href="#"
                class="text-decoration-none text-dark"
                @click.prevent="handleItemClick(city)"
                ><i class="bi bi-pencil"></i>{{ city.name_mun }}</a
              >
            </td>
            <td>IV District</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="submit" class="btn btn-success d-none">Search</button>
  </form>
  <MediumSizedModal :title="modalTitle" @close="toggleModal" :show="showModal">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>District</label>
        <select class="form-select" v-model="currentCity.districtId_mun">
          <option value="0">IV District</option>
        </select>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input class="form-control" required type="text" v-model="currentCity.name_mun" />
      </div>
      <div class="form-group mt-3">
        <button type="submit" class="btn btn-success w-100">Save Changes</button>
      </div>
    </form>
  </MediumSizedModal>
</template>

<script>
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import { onMounted, ref } from "vue";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import toastService from "@/services/toastService";
import confirmationService from "@/services/confirmationService";

export default {
  components: {
    MediumSizedModal,
  },
  setup() {
    const cities = ref([]);
    const searchModel = ref({});
    const currentCity = ref({});
    const showModal = ref(false);
    const modalTitle = ref("City Information");
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const handleSearch = async () => {
      spinnerService.show();
      cities.value = await areaService.searchMunicipalities(searchModel.value);
      spinnerService.hide();
    };
    const handleItemClick = (item) => {
      currentCity.value = { ...item };
      toggleModal();
    };
    const handleSubmit = async () => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to save changes?"
      );
      if (!confirmed) return;
      spinnerService.show();
      const response = await areaService.saveCity(currentCity.value);
      spinnerService.hide();
      if (response.success) {
        const idx = cities.value.findIndex((x) => x.id_mun === currentCity.value.id_mun);
        if (idx > -1) {
          cities.value[idx] = currentCity.value;
        }
        toastService.success("Action successful.");
        toggleModal();
      } else {
        toastService.error(response.message);
      }
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      cities,
      searchModel,
      handleSearch,
      currentCity,
      handleSubmit,
      handleItemClick,
      toggleModal,
      showModal,
      modalTitle,
    };
  },
};
</script>
