<template>
  <div class="container">
    <div class="btn-group mb-4">
      <button @click="save" class="btn btn-primary">
        <i class="bi bi-save"></i> Save
      </button>
      <button @click="prev" class="btn btn-secondary">
        <i class="bi bi-chevron-left"></i> Prev
      </button>
      <button @click="next" class="btn btn-secondary">
        Next <i class="bi bi-chevron-right"></i>
      </button>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>Household Name:</label>
        <input required class="form-control" v-model="loadedInfo.household.name_hh" />
      </div>
      <div class="form-group">
        <label>City/Municipality:</label>
        <select
          @change="onCityChanged"
          class="form-select"
          v-model="loadedInfo.household.cityid_hh"
        >
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Barangay:</label>
        <select class="form-select" v-model="loadedInfo.household.brgyid_hh">
          <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
            {{ brgy.name_brgy }}
          </option>
        </select>
      </div>
    </form>
    <div class="form-group">
      <label>Search for voter</label>
      <SearchableDropdown
        :cityid="loadedInfo.household.cityid_hh"
        :brgyid="loadedInfo.household.brgyid_hh"
        :isClearItemsInvoked="clearItems"
        @update:modelValue="handleSelection"
      />
    </div>

    <div>
      <div class="table-responsive">
        <table class="table align-items-center">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="voter in loadedInfo.members" :key="voter.syspk_voter">
              <td>{{ voter.name_voter }}</td>
              <td>{{ voter.type_voter }}</td>
              <td class="text-end">
                <div class="btn-group">
                  <button @click="handleRemove(voter)" class="btn btn-sm btn-danger">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import householdService from "@/services/householdService";
import spinnerService from "@/services/spinnerService";
import areaService from "@/services/areaService";
import confirmationService from "@/services/confirmationService";
import toastService from "@/services/toastService";
import SearchableDropdown from "../customs/SearchableDropdown.vue";
export default {
  components: {
    SearchableDropdown,
  },
  props: {
    id: String,
  },
  emits: ["prev", "next"],
  setup(props, { emit }) {
    const loadedInfo = ref({
      household: { name_hh: "", cityid_hh: 0, brgyid_hh: 0 },
      members: [],
    });
    const clearItems = ref(false);
    const cities = ref([]);
    const brgys = ref([]);
    const getInfo = async (id) => {
      resetItems();
      if (id === null) {
        loadedInfo.value = {
          household: { name_hh: "", cityid_hh: 0, brgyid_hh: 0, syspk_hh: null },
          members: [],
        };
        await onCityChanged();
      } else {
        spinnerService.show();
        loadedInfo.value = await householdService.getInfo(id);
        await onCityChanged();
        spinnerService.hide();
      }
    };
    const onCityChanged = async () => {
      brgys.value = await areaService.getBrgys(loadedInfo.value.household.cityid_hh);
    };
    const handleSubmit = async () => {
      alert(JSON.stringify(loadedInfo.value));
    };
    const handleRemove = async (voter) => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to remove this item?"
      );
      if (confirmed) {
        const response = await householdService.removeVoterFromHousehold(
          voter.syspk_voter
        );
        if (response.success) {
          toastService.success("Item has been removed successfully!");
          let idx = loadedInfo.value.members.findIndex(
            (x) => x.syspk_voter === voter.syspk_voter
          );
          if (idx > -1) {
            loadedInfo.value.members.splice(idx, 1);
          }
        } else {
          toastService.error(response.message);
        }
      }
    };
    const handleSelection = async (val) => {
      if (val === null) return;
      if (
        loadedInfo.value.household.syspk_hh === null ||
        loadedInfo.value.household.syspk_hh === ""
      ) {
        toastService.error("Please save the household first before adding items.");
        return;
      }
      spinnerService.show();
      const response = await householdService.addvotertohousehold(
        val.syspk_voter,
        loadedInfo.value.household.syspk_hh
      );
      if (response.success) {
        toastService.success("Voter added successfully!");
        loadedInfo.value.members.push(val);
      } else {
        toastService.error(response.message);
      }
      resetItems();
      spinnerService.hide();
    };
    const resetItems = () => {
      if (clearItems.value === true) {
        clearItems.value = false;
        setTimeout(() => {
          clearItems.value = true;
        }, 100);
      } else {
        clearItems.value = true;
      }
    };

    const prev = () => {
      emit("prev");
    };

    const next = () => {
      emit("next");
    };
    const save = async () => {
      spinnerService.show();
      const response = await householdService.save(loadedInfo.value.household);
      if (response.success) {
        toastService.success("Household saved successfully!");
        if (
          loadedInfo.value.household.syspk_hh === null ||
          loadedInfo.value.household.syspk_hh === ""
        ) {
          loadedInfo.value.household.syspk_hh = response.ref;
        }
      } else {
        toastService.error(response.message);
      }
      spinnerService.hide();
    };

    watch(
      () => props.id,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          getInfo(newVal);
        }
      }
    );
    onMounted(async () => {
      cities.value = await areaService.getMunicipalities();
    });
    return {
      loadedInfo,
      handleSubmit,
      onCityChanged,
      cities,
      brgys,
      handleRemove,
      handleSelection,
      clearItems,
      prev,
      next,
      save,
    };
  },
};
</script>
