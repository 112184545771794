<template>
  <div v-if="!isProcessing">
    <!--  <div v-if="!hasAccess" class="text-center">
            <div class="alert alert-danger">
                CONTENT IS CLASSIFIED
            </div>
        </div> -->

    <div class="table-responsive" style="overflow-y: auto; max-height: 500px">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="[
              { 'table-danger': voter.type_voter === 'LEADER' },
              { 'table-warning': voter.type_voter === 'SUB-LEADER' },
            ]"
            v-for="voter in groupings"
            :key="voter.syspk_voter"
          >
            <td>{{ voter.name_voter }}</td>
            <td>{{ voter.type_voter }}</td>
          </tr>

          <tr v-if="groupings === null || groupings.length === 0">
            <td colspan="3">
              <div class="alert alert-info text-center">Not assigned to household.</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else class="text-center vh-100">
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import voterService from "@/services/voterService";
import { computed, ref, watch } from "vue";

export default {
  props: {
    id: String,
  },
  setup(props) {
    const groupings = ref([]);
    const isProcessing = ref(false);
    const currentId = computed(() => props.id);
    const getHouseholdContent = async () => {
      if (currentId.value === null || currentId.value === "") return;
      isProcessing.value = true;
      const response = await voterService.getHouseholdContent(currentId.value);
      groupings.value = response;
      isProcessing.value = false;
    };
    watch(currentId, (newValue, oldValue) => {
      if (newValue !== oldValue) getHouseholdContent();
    });
    return {
      groupings,
      isProcessing,
    };
  },
};
</script>
