<template>
  <h3><i class="bi bi-geo-alt-fill me-1"></i>Heat Map</h3>
  <HeatMapSetup />
</template>
<script>
import HeatMapSetup from "@/components/gmaps/HeatMapSetup.vue";

export default {
  components: {
    HeatMapSetup,
  },
};
</script>
