
// google-maps-config.js

import { Loader } from '@googlemaps/js-api-loader';

// Replace with your actual Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyAJC9IxpbciCAl9qflVmDSJer2soKVsgPQ';

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['drawing'], // Ensure the drawing library is loaded
});

export default loader;
