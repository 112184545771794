<template>
  <div>Voters</div>
  <VotersTable />
</template>
<script>
import VotersTable from "@/components/voters/VotersTable.vue";

export default {
  components: {
    VotersTable,
  },
  setup() {
    return {};
  },
};
</script>
