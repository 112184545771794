<template>
  <div class="text-center">
    <i class="bi bi-exclamation-circle" style="font-size: 4em; color: #dc3545"></i>
    <h1>405 Forbidden</h1>
    <p>You are not authorized to access this page.</p>
    <router-link :to="{ name: 'dashboard' }" class="return-button">
      <i class="bi bi-house-door"></i> Return Home
    </router-link>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
export default {
  name: "AdminPage",
  setup() {
    const store = useStore();
    onMounted(() => {
      // Dispatch action to show admin header (adjust the action name as per your Vuex store setup)
      store.dispatch("showAdminHeader", false);
    });
  },
};
</script>

<style scoped>
/* Scoped styles for NotFound.vue */
h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
}

.text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

.return-button {
  margin-top: 20px;
  /* Adjust spacing */
  padding: 12px 20px;
  font-size: 1em;
  background-color: #007bff;
  /* Blue button color */
  color: #fff;
  /* White text color */
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.return-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}
</style>
