import store from '@/store'; 

const spinnerService = {
    show(){
        store.dispatch('showSpinner');
    },
    hide(){
        store.dispatch('hideSpinner');
    }
}

export default spinnerService;