<template>
  <div
    :class="['modal', 'fade', { show: show, active: show }]"
    v-show="show"
    id="mediumModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mediumModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            @click="close"
            type="button"
            class="btn btn-danger close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="modalBody" class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default() {
        return "Modal Title";
      },
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return {
      close,
    };
  },
};
</script>

<style scoped>
.show {
  display: block;
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.9s ease;
  /* Transition effect */
}

.show.active {
  opacity: 1;
}

/* Responsive width for the modal dialog */
.modal-dialog.modal-md {
  max-width: 50%; /* Default size for larger screens */
}

@media (max-width: 768px) {
  .modal-dialog.modal-md {
    max-width: 90%; /* Adjust to fit mobile screens */
  }
}

@media (max-width: 576px) {
  .modal-dialog.modal-md {
    max-width: 100%; /* Full width on extra small screens */
  }
}
</style>
