export function parseCoordinatesString(coordinatesString) {
  // Extract the coordinate pairs from the string
  var pairs = coordinatesString.match(/-?\d+\.\d+/g);

  // Create an array of LatLng objects
  var coordinatesArray = [];
  for (var i = 0; i < pairs.length; i += 2) {
      var lat = parseFloat(pairs[i + 1]);
      var lng = parseFloat(pairs[i]);
      coordinatesArray.push({ lat: lat, lng: lng });
  }

  return coordinatesArray;
}

export function coordinatesToWKT(jsonCoordinates) {
  if (!jsonCoordinates) {
      console.error("Invalid coordinates format");
      return null;
  }

  const coordinates = JSON.parse(jsonCoordinates);

  // Ensure that the polygon is closed by making the first and last coordinates identical
  if (coordinates.length > 0) {
      coordinates.push(coordinates[0]);
  }

  const wktString = "POLYGON ((" +
      coordinates.map(coord => coord.lng + " " + coord.lat).join(", ") +
      "))";

  return wktString;
}


