<template>
  <div class="row">
    <h5 class="card-title">
      Online Users <span class="badge bg-success text-light">{{ totalOnline }}</span>
    </h5>
    <div v-if="previewUsers.length !== 0">
      <div class="row">
        <div class="col-12" v-for="(user, index) in previewUsers" :key="index">
          <div class="card mb-3">
            <a href="#" @click.prevent="userClicked(user)" class="text-decoration-none">
              <div class="card-body d-flex align-items-center">
                <i class="bi bi-person-circle fs-3 text-primary me-3"></i>
                <div>
                  <h6 class="card-title mb-0">{{ user.userName }}</h6>
                  <p class="card-text mb-0 text-muted">
                    <i v-if="user.platform === 'web'" class="bi bi-browser-chrome"></i>
                    <i v-else class="bi bi-android2"></i>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="previewUsers.length < totalOnline"
      class="btn btn-info w-100 mt-3"
      @click="loadMoreUsers"
    >
      View More
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalOnline: Number,
    previewUsers: Object,
  },
  emits: ["user-clicked"],
  setup(props, { emit }) {
    const userClicked = async (user) => {
      emit("user-clicked", user);
    };
    return {
      userClicked,
    };
  },
};
</script>
