<template>
  <div v-if="!isProcessing">
    <div v-if="contentAction === 'showInfo'" class="container mt-3">
      <div class="d-flex justify-content-center align-items-center">
        <!-- Left arrow -->
        <button
          @click="previousImage"
          v-if="currentIndex > 0 && imgUrls.length > 1"
          class="btn btn-secondary btn-sm"
        >
          <i class="bi bi-chevron-left"></i>
        </button>

        <!-- Image -->
        <img :src="imgUrl" class="img-fluid mx-4" alt="Banner Image" />

        <!-- Right arrow -->
        <button
          @click="nextImage"
          v-if="currentIndex < imgUrls.length - 1 && imgUrls.length > 1"
          class="btn btn-secondary btn-sm"
        >
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
      <h2 class="text-center">
        {{ title }}
        <span class="badge bg-primary badge-exponent text-light rounded-circle">{{
          persons.length
        }}</span>
      </h2>
      <div class="row">
        <div
          v-for="person in persons"
          :key="person.syspk_voter"
          class="col-md-6 col-lg-6 mb-3"
        >
          <div class="card" :class="{ 'bg-success': person.syspk_voter === personId }">
            <div class="card-body">
              <h5 class="card-title">
                <i class="bi bi-person"></i> {{ person.name_voter }}
              </h5>
              <p class="card-text">
                <i class="bi bi-calendar3"></i> <strong>Gender:</strong>
                {{ person.gender_voter }}
              </p>
              <p class="card-text">
                <i class="bi bi-briefcase"></i> <strong>Type:</strong>
                {{ person.type_voter }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HouseholdInMarker
      v-else
      :households="households"
      @household-selected="onHouseholdSelected"
    />
  </div>
  <div v-else class="text-center">
    <div class="spinner-container">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import HouseholdInMarker from "./HouseholdInMarker.vue";
import areaService from "@/services/areaService";
import { apiConfig } from "@/utils/config";

export default {
  components: {
    HouseholdInMarker,
  },
  props: {
    id: String,
    personId: { type: Number, default: () => 0 },
    coordinates: { type: Object, default: () => null },
  },
  setup(props) {
    const contentAction = ref("showOptions");
    const isProcessing = ref(false);
    const households = ref([]);
    const persons = ref([]);
    const title = ref("");
    const imgUrl = ref("/img-place-holder.jpg");
    const imgUrls = ref([]);
    const currentIndex = ref(0);
    /*  const getInfo = async (id) => {
          if (id === null) return;
        }; */
    const onHouseholdSelected = async (household) => {
      isProcessing.value = true;
      contentAction.value = "showInfo";
      title.value = household.name_hh.toUpperCase();
      const response = await areaService.getHouseholdContentById(household.syspk_hh);
      currentIndex.value = 0;
      persons.value = response.persons;
      imgUrls.value = response.urls;
      if (imgUrls.value.length > 0) {
        imgUrl.value =
          (process.env.VUE_APP_API_URL || apiConfig.baseUrl) + imgUrls.value[0].url;
      }
      isProcessing.value = false;
    };
    const getHouseholdsByCoordinates = async (coords) => {
      contentAction.value = "showOptions";
      isProcessing.value = true;
      const response = await areaService.getHouseholdsByCoordinates(coords);
      households.value = response;
      isProcessing.value = false;
    };
    const previousImage = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        imgUrl.value =
          (process.env.VUE_APP_API_URL || apiConfig.baseUrl) +
          imgUrls.value[currentIndex.value].url;
      }
    };
    const nextImage = () => {
      if (currentIndex.value < imgUrls.value.length) {
        currentIndex.value += 1;
        imgUrl.value =
          (process.env.VUE_APP_API_URL || apiConfig.baseUrl) +
          imgUrls.value[currentIndex.value].url;
      }
    };
    onMounted(() => {
      if (props.coordinates !== null) getHouseholdsByCoordinates(props.coordinates);
    });
    watch(
      () => props.coordinates,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal !== null) {
          //getInfo(newVal);
          getHouseholdsByCoordinates(newVal);
        }
      }
    );
    return {
      persons,
      contentAction,
      households,
      onHouseholdSelected,
      isProcessing,
      title,
      imgUrl,
      previousImage,
      nextImage,
      currentIndex,
      imgUrls,
    };
  },
};
</script>
<style scoped>
.card-title i,
.card-text i {
  margin-right: 5px;
}

.badge-exponent {
  position: relative;
  top: -0.4em; /* Adjust the value as needed for proper alignment */
  font-size: 0.8em; /* Adjust the font size as needed */
}

@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
