import store from '@/store'; 

const toastService = {
    success(message){
        store.dispatch('successToast',message);
    },
    error(message){
        store.dispatch('errorToast',message);
    }
}

export default toastService;