<!-- App.vue -->
<template>
  <div>
    <div class="row">
      <div class="col-lg-3 mb-2">
        <select
          @change="handleCityChanged"
          class="form-select"
          v-model="searchOptions.city"
        >
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>
      <div class="col-lg-3 mb-2">
        <select
          @change="handleBrgyChanged"
          class="form-select"
          v-model="searchOptions.brgy"
        >
          <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
            {{ brgy.name_brgy }}
          </option>
        </select>
      </div>
      <div class="col-lg-3 mb-2">
        <PaginationControl
          @page-change="onPageChanged"
          :page="p.currentPage"
          :items="p.totalItems"
          :pages="p.totalPages"
        />
      </div>
      <div class="col-lg-3 mb-2 text-end">
        <button @click="generatePdf" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
        </button>
      </div>
    </div>
    <div class="card-container" ref="idCards">
      <IDCard
        v-for="(user, index) in users"
        :key="index"
        :companyName="getCompanyName(searchOptions.city)"
        :name="user.name_voter"
        :idNumber="user.precinct_voter"
        :contactInfo="user.brgy_voter"
        :logoUrl="user.logoUrl"
        :photoUrl="user.qrimage_voter"
        :style="getCardStyle(index)"
      />
    </div>
  </div>
</template>

<script>
import IDCard from "@/components/customs/IDCard.vue";
import PaginationControl from "@/components/PaginationControl.vue";
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import voterService from "@/services/voterService";
import html2pdf from "html2pdf.js";
import { onMounted, ref } from "vue";

export default {
  components: { IDCard, PaginationControl },
  setup() {
    const users = ref([]);
    const p = ref({ currentPage: 1, totalItems: 0, totalPages: 1 });
    const searchOptions = ref({ city: 0, brgy: 0 });
    const cities = ref([]);
    const brgys = ref([]);

    const getBrgys = async () => {
      brgys.value = await areaService.getBrgys(searchOptions.value.city);
    };
    const getMunicipalities = async () => {
      cities.value = await areaService.getMunicipalities();
      if (cities.value !== null && cities.value.length > 1) {
        searchOptions.value.city = cities.value[1].id_mun;
        await getBrgys();
      }
    };

    const getCompanyName = (cityId) => {
      switch (cityId) {
        case 1:
          return "LEYTE 4TH DISTRICT";
        case 4:
          return "Ormoc True Value";
        default:
          return "LEYTE 4TH DISTRICT";
      }
    };

    const handleBrgyChanged = () => {
      p.value.currentPage = 1;
      fetchData(p.value.currentPage);
    };
    const handleCityChanged = () => {
      getBrgys();
    };
    const onPageChanged = async (page) => {
      await fetchData(page);
      p.value.currentPage = page;
    };
    const generatePdf = async () => {
      const element = document.querySelector(".card-container");

      if (!element) {
        console.error("Element not found");
        return;
      }
      spinnerService.show();
      const idx = brgys.value.findIndex((x) => x.id_brgy === searchOptions.value.brgy);
      let title = "id_cards.pdf";
      if (idx > -1) {
        title = `${brgys.value[idx].name_brgy}-${p.value.currentPage} of ${p.value.totalPages}.pdf`;
      }

      const opt = {
        margin: [10, 10],
        filename: title,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          ignoreElements: (el) => el.classList.contains("avoid-page-break"),
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // A4 size
        //jsPDF: { unit: "mm", format: [215.9, 330.2], orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
      };

      try {
        console.log("Generating PDF...");
        await html2pdf().from(element).set(opt).save();
        console.log("PDF generated successfully.");
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        spinnerService.hide();
      }
    };

    const fetchData = async (page) => {
      try {
        spinnerService.show();
        const response = await voterService.getQrCodesForPrinting(
          searchOptions.value.brgy,
          page
        );
        users.value = response.voters;
        p.value.currentPage = page;
        p.value.totalItems = response.totalItems;
        p.value.totalPages = response.totalPages;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        spinnerService.hide();
      }
    };

    const getCardStyle = (index) => {
      const marginBottom = 10; // Default margin between rows in mm

      // Add extra margin to every 9th and 10th item
      if ((index + 1) % 9 === 0 || (index + 1) % 10 === 0) {
        return { marginBottom: `${Math.ceil(marginBottom * 1.8)}px` };
      }
      return { marginBottom: `${marginBottom}px` };
    };

    onMounted(() => {
      getMunicipalities();
    });

    return {
      generatePdf,
      users,
      getCardStyle,
      searchOptions,
      cities,
      brgys,
      handleCityChanged,
      handleBrgyChanged,
      onPageChanged,
      getCompanyName,
      p,
    };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.id-card-container {
  width: 63mm; /* Width to fit 3 per row */
  height: 95mm; /* Height to fit vertically */
  margin-bottom: 10mm; /* Default margin between rows */
}

/* Prevent extra page breaks */
.avoid-page-break {
  page-break-inside: avoid;
}
</style>
