import apiService from "./apiService";

const userService = {
    async submitLogin(credentials) {
        apiService.submitLogin(`u/submitlogin`, credentials, false);
    },
    async searchUsers(searchModel) {
        try {
            return (await apiService.get(`u/search`, searchModel, true)).data;
        }
        catch {
            return [];
        }
    },
    async toggleUser(params) {
        try {
            return (await apiService.post(`u/toggle/${params.id}/${params.state}`, {}, true)).data;
        }
        catch {
            return { success: false, message: "An error occured while processing the request." };
        }
    },
    async saveUserAssignments(user) {
        try {
            return (await apiService.post(`u/saveuserassignments`, {
                syspk_user: user.syspk_user, assignedbrgyids_user: user.assignedbrgyids_user, pcrole_user: user.pcrole_user,
                name_user: user.name_user, fullname_user: user.fullname_user, mobileno_user: user.mobileno_user, pword_user: user.pword_user, assignedcityids_user: user.assignedcityids_user
            }, true)).data
        }
        catch {
            return { success: false, message: "An error occured while processing the request." };
        }
    },
    async getRecentLogs() {
        try {
            return (await apiService.get(`u/getrecentlogs`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getLastKnownLocation(id) {
        try {
            return (await apiService.get(`u/getlastknownlocation/${id}`, {}, true)).data;
        }
        catch {
            return { syspk_loc: 0, id: 0, userid_loc: 0, lat_loc: 0, lng_loc: 0, accuracy_loc: 0, timestamp_loc: "0001-01-01T00:00:00" };
        }
    },
    async getPaginatedUsers(id, page, rows) {
        try {
            return (await apiService.get(`u/paginated-users/${id}/${page}/${rows}`)).data;
        }
        catch {
            return [];
        }
    }
};

export default userService;