<template>
  <h3><i class="bi bi-geo-alt-fill me-1"></i> Heat Map</h3>
  <HeatMap />
</template>
<script>
import HeatMap from "@/components/gmaps/HeatMap.vue";

export default {
  components: {
    HeatMap,
  },
};
</script>
