<template>
  <div class="btn-group">
    <button type="button" @click="save" :disabled="isProcessing" class="btn btn-primary">
      <i class="bi bi-save"></i> Save
    </button>
    <button
      type="button"
      v-if="loadedInfo.voter.syspk_voter !== 0"
      @click="prev"
      :disabled="isProcessing"
      class="btn btn-secondary"
    >
      <i class="bi bi-arrow-left"></i> Prev
    </button>
    <button
      @click="next"
      v-if="loadedInfo.voter.syspk_voter !== 0"
      type="button"
      :disabled="isProcessing"
      class="btn btn-secondary"
    >
      Next <i class="bi bi-arrow-right"></i>
    </button>
  </div>
  <div v-if="!isProcessing" class="row">
    <input
      type="file"
      id="fileInput"
      onchange="uploadFile()"
      class="d-none"
      accept="image/*"
    />
    <form @submit.prevent="saveVoter" id="form-voter-info">
      <div style="text-align: center">
        <div style="text-align: center; position: relative">
          <div style="position: relative; display: inline-block">
            <img
              class="rounded-circle"
              style="width: 150px; height: 150px; object-fit: cover"
              id="voter-pic"
              alt="Profile Picture"
              :src="loadedInfo.voter.profilepic_voter"
            />

            <div style="position: absolute; bottom: 0; right: 0">
              <!-- Add your camera icon here -->
              <button type="button" id="btn-upload-voter-pic" class="btn btn-secondary">
                <i class="bi bi-camera" style="font-size: 1.3rem"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label class="form-label" for="Voter_@nameof(Model.Voter.name_voter)"
            >Name</label
          >
          <input v-model="loadedInfo.voter.name_voter" required class="form-control" />
        </div>

        <div class="form-group col-md-1">
          <label class="form-label">Gender</label>
          <select required v-model="loadedInfo.voter.gender_voter" class="form-select">
            <option value=""></option>
            <option value="M">M</option>
            <option value="F">F</option>
          </select>
        </div>
        <div class="form-group col-md-1">
          <label class="form-label" for="Voter_@nameof(Model.Voter.birth_voter)"
            >Birth Date</label
          >
          <input class="form-control" v-model="loadedInfo.voter.birth_voter" />
        </div>
        <div class="form-group col-md-1">
          <label class="form-label" for="Voter_@nameof(Model.Voter.precinct_voter)"
            >Precinct No.</label
          >
          <input v-model="loadedInfo.voter.precinct_voter" class="form-control" />
        </div>
        <div class="form-group col-md-2">
          <label class="form-label" for="Voter_@nameof(Model.Voter.title_voter)"
            >Sitio</label
          >
          <input v-model="loadedInfo.voter.sitio_voter" class="form-control" />
        </div>
        <div class="form-group col-md-2">
          <label class="form-label">VIN</label>
          <input v-model="loadedInfo.voter.vin_voter" class="form-control" />
        </div>
        <div class="form-group col-md-1">
          <label class="form-label">Type</label>
          <select v-model="loadedInfo.voter.type_voter" class="form-select">
            <option value="MEMBER">MEMBER</option>
            <option value="LEADER">LEADER</option>
            <option value="BOMTE">BOMTE</option>
            <option value="SUB-LEADER">SUB-LEADER</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <label class="form-label" for="Voter_@nameof(Model.Voter.municipality_voter)"
            >City/Municipality</label
          >
          <select required v-model="loadedInfo.voter.cityid_voter" class="form-select">
            <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
              {{ city.name_mun }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="Voter_@nameof(Model.Voter.brgyid_voter)"
            >Brgy</label
          >
          <select required v-model="loadedInfo.voter.brgyid_voter" class="form-select">
            <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
              {{ brgy.name_brgy }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label">Sector</label>
          <div class="input-group">
            <div :style="boxStyle"></div>
            <select class="form-select" v-model="loadedInfo.voter.title_voter">
              <option
                v-for="(sector, index) in sectors"
                :key="index"
                :value="sector.sectorName"
              >
                {{ sector.sectorName }}
              </option>
            </select>
            <button
              @click.prevent="handleAddNewSector"
              type="button"
              class="btn btn-sm btn-success"
              title="Add"
            >
              <i class="bi bi-plus"></i>
            </button>
          </div>
          <!-- The Input Field for entering a new value -->
          <div class="input-group mt-2" v-if="isAdding">
            <input
              type="text"
              v-model="newSector"
              placeholder="Enter new sector"
              class="form-control mt-2"
            />
            <button
              @click.prevent="handleSelectNewSector"
              type="button"
              class="btn btn-sm btn-success"
              title="Select"
            >
              <i class="bi bi-check"></i>
            </button>
            <button
              @click.prevent="handleCancelAdd"
              type="button"
              class="btn btn-sm btn-danger"
              title="Select"
            >
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>

        <div class="form-group col-md-3">
          <label class="form-label" for="">Sector-Position</label>
          <input
            type="text"
            class="form-select"
            v-model="loadedInfo.voter.kagawad_voter"
          />
        </div>

        <div class="form-group col-md-4">
          <label class="form-label">BOMTE/Principal Leader</label>
          <select class="form-select" v-model="loadedInfo.voter.pleader_voter">
            <option
              v-for="pleader in pleaders"
              :key="pleader.syspk_voter"
              :value="pleader.name_voter"
            >
              {{ pleader.name_voter }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label class="form-label" for="Voter_@nameof(Model.Voter.leader_voter)"
            >Leader/POMTE</label
          >
          <select class="form-select" v-model="loadedInfo.voter.leader_voter">
            <option
              v-for="leader in leaders"
              :key="leader.syspk_voter"
              :value="leader.name_voter"
            >
              {{ leader.name_voter }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label class="form-label" for="">Sub-Leader/A1</label>
          <select class="form-select" v-model="loadedInfo.voter.subleader_voter">
            <option
              v-for="subleader in subleaders"
              :key="subleader.syspk_voter"
              :value="subleader.name_voter"
            >
              {{ subleader.name_voter }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-2">
          <label class="form-label" for="Voter_@nameof(Model.Voter.loyaltystate_voter)"
            >Level of Support</label
          >

          <select v-model="loadedInfo.voter.loyaltystate_voter" class="form-select">
            <option :value="null"></option>
            <option value="C">C</option>
            <option value="V">V</option>
            <option value="CV">CV</option>
            <option value="ODT">ODT</option>
            <option value="?">?</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label class="form-label" for="Voter_@nameof(Model.Voter.sysfk_hh_voter)"
            >HouseHold</label
          >
          <select v-model="loadedInfo.voter.sysfk_hh_voter" class="form-select">
            <option
              v-for="household in households"
              :key="household.syspk_hh"
              :value="household.syspk_hh"
            >
              {{ household.name_hh }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <div class="form-check d-flex" style="margin-top: 40px">
            <input
              type="checkbox"
              id="issupporter"
              v-model="loadedInfo.voter.side_voter"
              class="form-check-input form-check-input-lg"
              style="width: 1.5em; height: 1.5em"
            />
            <label for="issupporter" class="ms-2">Supporter</label>
          </div>
        </div>
        <div class="form-group col-md-2">
          <div class="form-check d-flex" style="margin-top: 40px">
            <input
              type="checkbox"
              id="gift1"
              v-model="loadedInfo.voter.gift1_voter"
              class="form-check-input form-check-input-lg"
              style="width: 1.5em; height: 1.5em"
            />
            <label for="gift1" class="ms-2">Initial Confirmation</label>
          </div>
        </div>
        <div class="form-group col-md-2">
          <div class="form-check d-flex" style="margin-top: 40px">
            <input
              type="checkbox"
              id="gift2"
              v-model="loadedInfo.voter.gift2_voter"
              class="form-check-input form-check-input-lg"
              style="width: 1.5em; height: 1.5em"
            />
            <label for="gift2" class="ms-2">Final Confirmation</label>
          </div>
        </div>
        <div class="form-group col-md-2">
          <div class="form-check d-flex" style="margin-top: 40px">
            <input
              type="checkbox"
              id="isprinted"
              v-model="loadedInfo.voter.isprinted_voter"
              class="form-check-input form-check-input-lg"
              style="width: 1.5em; height: 1.5em"
              name="isprinted_voter"
            />
            <label for="isprinted" class="ms-2">QR Printed</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>Remarks</label>
          <textarea
            class="form-control"
            v-model="loadedInfo.voter.remarks_voter"
          ></textarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="card">
          <div class="card-body pt-3">
            <!-- Bordered Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered">
              <li class="nav-item">
                <button
                  type="button"
                  class="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#voter-edit-history"
                >
                  <i class="bi bi-clock"></i> Edit History
                </button>
              </li>

              <li class="nav-item">
                <button
                  type="button"
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#voter-groupings"
                  @click="tabChanged('groupings')"
                >
                  <i class="bi bi-people"></i> Groupings
                </button>
              </li>

              <li class="nav-item">
                <button
                  type="button"
                  class="nav-link"
                  data-bs-toggle="tab"
                  @click="tabChanged('households')"
                  data-bs-target="#voter-household"
                >
                  <i class="bi bi-house"></i> Household Info.
                </button>
              </li>
            </ul>
            <div class="tab-content pt-2">
              <!-- Start Real Property Kind -->
              <div
                class="tab-pane fade show active"
                style="min-height: 350px"
                id="voter-edit-history"
              >
                <!--    <div v-if="!hasAccess" class="text-center">
                                    <div class="alert alert-danger">
                                        CONTENT IS CLASSIFIED
                                    </div>
                                </div>
 -->

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Header</th>
                      <th>Content</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in loadedInfo.logs" :key="log.id_voteractlog">
                      <td v-html="log.header_voteractlog"></td>
                      <td>{{ log.content_voteractlog }}</td>
                      <td>{{ log.created_voteractlog }}</td>
                    </tr>

                    <tr v-if="loadedInfo === null || loadedInfo.logs.length === 0">
                      <td colspan="3">
                        <div class="alert alert-info text-center">No available logs.</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Start Real Property Kind -->
              <div class="tab-pane fade" style="min-height: 350px" id="voter-groupings">
                <!--  <div v-if="!hasAccess" class="text-center">
                                    <div class="alert alert-danger">
                                        CONTENT IS CLASSIFIED
                                    </div>
                                </div> -->
                <VoterGroup :id="currentId" :leader="loadedInfo.voter.leader_voter" />
              </div>
              <div class="tab-pane fade" style="min-height: 350px" id="voter-household">
                <VoterHouseholdGroup :id="currentHHId" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary d-none" id="btnSubmitVoter">
        Save
      </button>
    </form>
  </div>
  <div v-else class="text-center">
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import voterService from "@/services/voterService";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import VoterGroup from "./VoterGroup.vue";
import VoterHouseholdGroup from "./VoterHouseholdGroup.vue";
import toastService from "@/services/toastService";
import confirmationService from "@/services/confirmationService";
import { hasRoles } from "@/utils/auth";
export default defineComponent({
  components: {
    VoterGroup,
    VoterHouseholdGroup,
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  emits: ["prev", "next", "reset"],
  setup(props, { emit }) {
    const loadedInfo = ref({
      voter: { brgyid_voter: 0, leader_voter: "", title_voter: "" },
      groupings: [],
      hhcontent: {},
      logs: [],
    });
    const leaders = ref([]);
    const subleaders = ref([]);
    const hasAccess = ref(false);
    const households = ref([]);
    const cities = ref([]);
    const sectors = ref([]);
    const currentId = ref(0);
    const currentHHId = ref("");
    const brgys = ref([]);
    const pleaders = ref([]);
    const isProcessing = ref(false);
    const hasRequiredRole = (requiredRoles) => {
      return hasRoles(requiredRoles);
    };
    const getInfo = async () => {
      if (props.id === -1) return;
      if (props.id === 0) {
        cities.value = await areaService.getMunicipalities();
        sectors.value = await voterService.getSectors();
        loadedInfo.value = {
          voter: { brgyid_voter: 0, leader_voter: "", syspk_voter: 0, title_voter: "" },
          groupings: [],
          hhcontent: {},
          logs: [],
        };
        return;
      }
      isProcessing.value = true;
      const temp = await voterService.getInfo(props.id);
      cities.value = await areaService.getMunicipalities();
      brgys.value = await areaService.getBrgys(temp.voter.cityid_voter);
      await getPresets();
      /*  if (temp.leader_voter !== null && temp.leader_voter !== "")
                    await getSubleaders(temp.voter.leader_voter); */
      loadedInfo.value.voter = temp.voter;
      await getSubleaders();
      loadedInfo.value.logs = temp.editLogs;
      isProcessing.value = false;
    };
    const getPresets = async () => {
      spinnerService.show();
      const result = await voterService.getPresets(loadedInfo.value.voter.brgyid_voter);
      leaders.value = result.leaders;
      leaders.value.unshift({ syspk_voter: 0, name_voter: null });
      households.value = result.houseHolds;
      pleaders.value = result.pLeaders;
      pleaders.value.unshift({ syspk_voter: 0, name_voter: null });
      spinnerService.hide();
    };
    const getSubleaders = async () => {
      /*  if (
        loadedInfo.value.voter.leader_voter === null ||
        loadedInfo.value.voter.leader_voter === ""
      ) {
        subleaders.value = [];
        loadedInfo.value.voter.subleader_voter = null;
        return;
      }*/
      const result = await voterService.getSubleaders(
        loadedInfo.value.voter.leader_voter,
        loadedInfo.value.voter.brgyid_voter
      );
      subleaders.value = result;
      subleaders.value.unshift({ syspk_voter: 0, name_voter: null });
    };
    onMounted(() => {
      getInfo();
    });
    const tabChanged = (tab) => {
      switch (tab) {
        case "groupings":
          getGroupings();
          break;
        case "households":
          getHouseholdContent();
          break;
        default:
          break;
      }
    };

    const getGroupings = () => {
      if (currentId.value !== loadedInfo.value.voter.syspk_voter) {
        currentId.value = loadedInfo.value.voter.syspk_voter;
      }
    };

    const getHouseholdContent = () => {
      if (currentHHId.value !== loadedInfo.value.voter.sysfk_hh_voter) {
        currentHHId.value = loadedInfo.value.voter.sysfk_hh_voter;
      }
    };
    const next = () => {
      emit("next");
    };
    const prev = () => {
      emit("prev");
    };
    const save = () => {
      document.getElementById("btnSubmitVoter").click();
    };
    const saveVoter = async () => {
      if (!hasRequiredRole(["admin", "web-voter-mod"])) {
        toastService.error("You are not allowed to perform this action");
        return;
      }
      const confirmed = await confirmationService.show(
        "Are you sure you want to save changes?"
      );
      if (!confirmed) return;
      try {
        spinnerService.show();
        const response = await voterService.saveVoter(loadedInfo.value.voter);
        if (response.data.success) {
          toastService.success(response.data.message);
          emit("reset");
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    const keywordColors = {
      YOUTH: "orange",
      WOMEN: "pink",
      VENDOR: "darkblue",
      FISHER: "gray",
      FARMER: "green",
      TRICYCLE: "brown",
      DEFAULT: "transparent",
    };

    const keywords = ["youth", "women", "vendor", "fisher", "farmer", "tricycle"];

    const getColorForKeyword = (str) => {
      if (str === null) {
        return keywordColors.DEFAULT; // Default color if null is selected
      }
      for (const keyword of keywords) {
        if (str.toUpperCase().includes(keyword.toUpperCase())) {
          return keywordColors[keyword.toUpperCase()];
        }
      }
      return keywordColors.DEFAULT; // Default color if no keyword is found
    };

    // Computed property to dynamically return the box style based on the selected keyword
    const boxStyle = computed(() => {
      const color = getColorForKeyword(loadedInfo.value.voter.title_voter);
      return {
        backgroundColor: color,
        padding: "20px",
        borderRadius: "8px",
        color: "#333",
        textAlign: "center",
      };
    });

    const newSector = ref(""); // Holds the new value entered by the user
    const isAdding = ref(false);

    const handleAddNewSector = () => {
      isAdding.value = !isAdding.value;
    };
    const handleSelectNewSector = () => {
      if (
        newSector.value === null ||
        newSector.value === "" ||
        newSector.value.trim() === ""
      ) {
        toastService.error("Please enter a valid value.");
        return;
      }
      sectors.value.push({ sectorName: newSector.value.toUpperCase() });
      loadedInfo.value.voter.title_voter = newSector.value.toUpperCase();
      newSector.value = "";
      isAdding.value = false;
    };

    const handleCancelAdd = () => {
      isAdding.value = false;
    };

    // Watch the `voterTitle` for changes and dynamically add new sectors
    watch(loadedInfo.value.title_voter, (newValue) => {
      if (newValue && !sectors.value.find((sector) => sector.sectorName === newValue)) {
        sectors.value.push({ sectorName: newValue });
      }
    });

    watch(
      () => props.id,
      () => {
        getInfo();
      }
    );

    watch(
      () => loadedInfo.value.voter.brgyid_voter,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue > 0) {
          getPresets(); // Call getPresets() function when the value changes
        }
      },
      { deep: true }
    );

    watch(
      () => loadedInfo.value.voter.leader_voter,
      (newValue, oldValue) => {
        //if (newValue !== oldValue && (newValue !== "" || newValue !== null)) {
        //loadedInfo.value.voter.subleader_voter = null;
        oldValue;
        newValue;
        getSubleaders();
        //}
      },
      { deep: true }
    );
    watch(
      () => loadedInfo.value.voter.cityid_voter,
      async (newValue, oldValue) => {
        if (newValue !== oldValue && newValue > 0) {
          brgys.value = await areaService.getBrgys(newValue);
        }
      },
      { deep: true }
    );

    return {
      loadedInfo,
      leaders,
      subleaders,
      hasAccess,
      households,
      cities,
      brgys,
      isProcessing,
      tabChanged,
      currentId,
      currentHHId,
      prev,
      next,
      save,
      saveVoter,
      hasRequiredRole,
      pleaders,
      sectors,
      newSector,
      handleAddNewSector,
      handleSelectNewSector,
      isAdding,
      handleCancelAdd,
      boxStyle,
    };
  },
});
</script>
