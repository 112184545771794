<template>
  <div class="text-end">
    <button type="button" @click="handleAddClick" class="btn btn-success">
      <i class="bi bi-plus"></i> Add Barangay
    </button>
  </div>
  <form @submit.prevent="handleSearch">
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Turnout Percentage</th>
          </tr>
          <tr>
            <th>
              <input class="form-control" v-model="searchModel.name_brgy" />
            </th>
            <th>
              <select
                @change="handleSearch"
                class="form-select"
                v-model="searchModel.cityId_brgy"
              >
                <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
                  {{ city.name_mun }}
                </option>
              </select>
            </th>
            <th>
              <input class="form-control" v-model="searchModel.latitude_brgy" />
            </th>
            <th>
              <input class="form-control" v-model="searchModel.longitude_brgy" />
            </th>
            <th>
              <input class="form-control" v-model="searchModel.turnoutPercentage_brgy" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="brgy in brgys" :key="brgy.id_brgy">
            <td>
              <a
                class="text-decoration-none text-dark"
                href="#"
                @click.prevent="handleClick(brgy)"
              >
                <i class="bi bi-pencil"></i> {{ brgy.name_brgy }}</a
              >
            </td>
            <td>{{ brgy.name_mun }}</td>
            <td>{{ brgy.latitude_brgy }}</td>
            <td>{{ brgy.longitude_brgy }}</td>
            <td>{{ brgy.turnoutPercentage_brgy }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="submit" class="btn btn-success d-none">Search</button>
  </form>
  <MediumSizedModal :title="modalTitle" @close="toggleModal" :show="showModal">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>District</label>
        <select class="form-select" v-model="currentBrgy.cityId_brgy">
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input
          class="form-control"
          required
          type="text"
          v-model="currentBrgy.name_brgy"
        />
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label>Latitude</label>
          <input class="form-control" type="text" v-model="currentBrgy.latitude_brgy" />
        </div>
        <div class="form-group col-lg-4">
          <label>Longitude</label>
          <input class="form-control" type="text" v-model="currentBrgy.longitude_brgy" />
        </div>
        <div class="form-group col-lg-4">
          <label>Percentage</label>
          <input
            class="form-control"
            type="text"
            v-model="currentBrgy.turnoutPercentage_brgy"
          />
        </div>
      </div>
      <div class="form-group mt-2">
        <button type="submit" class="btn btn-success w-100">Save Changes</button>
      </div>
    </form>
  </MediumSizedModal>
</template>
<script>
import areaService from "@/services/areaService";
import spinnerService from "@/services/spinnerService";
import { onMounted, ref } from "vue";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import confirmationService from "@/services/confirmationService";
import toastService from "@/services/toastService";

export default {
  components: {
    MediumSizedModal,
  },
  setup() {
    const brgys = ref([]);
    const searchModel = ref({});
    const cities = ref([]);
    const currentBrgy = ref({});
    const modalTitle = ref("Barangay Information");
    const showModal = ref(false);
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const handleSearch = () => {
      searchBrgys();
    };
    const searchBrgys = async () => {
      spinnerService.show();
      brgys.value = await areaService.searchBrgys(searchModel.value);
      spinnerService.hide();
    };

    const handleClick = (brgy) => {
      currentBrgy.value = { ...brgy };
      toggleModal();
    };
    const handleSubmit = async () => {
      if (currentBrgy.value.turnoutPercentage_brgy > 1.0) {
        toastService.error("Turnout percentage must be less than or equal to 1");
        return;
      }
      const confirmed = await confirmationService.show(
        "Are you sure you want to save changes?"
      );
      if (!confirmed) return;
      spinnerService.show();
      const response = await areaService.saveBrgy(currentBrgy.value);
      spinnerService.hide();
      if (response.success) {
        if (currentBrgy.value.id_mun === 0) {
          searchBrgys();
        } else {
          const idx = brgys.value.findIndex(
            (x) => x.id_brgy === currentBrgy.value.id_brgy
          );
          if (idx > -1) {
            brgys.value[idx] = currentBrgy.value;
          }
        }
        toastService.success("Action successful");
        toggleModal();
      } else {
        toastService.error(response.message);
      }
    };
    const handleAddClick = () => {
      let cityid = 0;
      if (cities.value.length > 1) {
        cityid = cities.value[1].id_mun;
      }
      currentBrgy.value = {
        id_brgy: 0,
        latitude_brgy: 0,
        longitude_brgy: 0,
        turnoutPercentage_brgy: 0.8,
        cityId_brgy: cityid,
      };
      toggleModal();
    };
    onMounted(async () => {
      cities.value = await areaService.getMunicipalities();
      searchBrgys();
    });

    return {
      brgys,
      cities,
      searchModel,
      handleSearch,
      handleClick,
      currentBrgy,
      modalTitle,
      showModal,
      toggleModal,
      handleSubmit,
      handleAddClick,
    };
  },
};
</script>
