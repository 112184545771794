<template>
  <div class="tab-content" v-show="title == selectedTitle">
    <slot />
  </div>
</template>
<script>
import { inject } from "vue";

export default {
  props: ["title"],
  name: "TabContent",
  setup() {
    const selectedTitle = inject("selectedTitle");

    return {
      selectedTitle,
    };
  },
};
</script>
<style scoped>
.tab-content {
  padding: 20px;
  min-height: 200px;
  border-top: 1px solid #ddd; /* Keep a simple top border for content */
}
</style>
