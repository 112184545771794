<template>
  <h3 class="mt-1"><i class="bi bi-person-fill-gear me-1"></i>Users Settings</h3>
  <UsersTable />
</template>
<script>
import UsersTable from "@/components/users/UsersTable.vue";

export default {
  components: {
    UsersTable,
  },
  setup() {},
};
</script>
