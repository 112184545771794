import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import store from './store';
import router from './router';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
});
store.dispatch('auth/initializeStore').then(() => {
    app.mount('#app');
});
