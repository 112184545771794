<template>
  <div>Households</div>
  <HouseholdsTable />
</template>

<script>
import HouseholdsTable from "@/components/households/HouseholdsTable.vue";

export default {
  components: {
    HouseholdsTable,
  },
  setup() {},
};
</script>
