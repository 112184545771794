import { createStore } from 'vuex';
import auth from './modules/auth';
import axios from 'axios';
import { apiConfig } from '@/utils/config';
import { useToast } from 'vue-toastification';
const store = createStore({
    state: {
        showSpinner: false,
        showAfterSave: false,
        showAdminHeader: true,
        confirmModal: {
            isVisible: false,
            message: '',
            resolve: null
        },
        costings: [{
            syspk_costing: 0
        }]
    },
    mutations: {
        SHOW_SPINNER(state) {
            state.showSpinner = true;
        },
        HIDE_SPINNER(state) {
            state.showSpinner = false;
        },
        SET_COSTINGS(state, { costings }) {
            state.costings = costings;
        },
        SHOW_CONFIRM_MODAL(state, { message, resolve }) {
            state.confirmModal.isVisible = true;
            state.confirmModal.message = message;
            state.confirmModal.resolve = resolve;
        },
        HIDE_CONFIRM_MODAL(state) {
            state.confirmModal.isVisible = false;
            state.confirmModal.message = '';
            state.confirmModal.resolve = null;
        },
        SHOW_AFTER_SAVE(state, { value }) {
            state.showAfterSave = value;
        },
        SHOW_ADMIN_HEADER(state, { value }) {
            state.showAdminHeader = value;
        },
    },
    actions: {
        showSpinner({ commit }) {
            commit('SHOW_SPINNER');
        },
        showAfterSave({ commit }, value) {
            commit('SHOW_AFTER_SAVE', { value })
        },
        async getCostings({ commit }) {
            try {
                const response = await axios.get(`${apiConfig.baseUrl}v1/b/getcostings`);
                commit('SET_COSTINGS', { costings: response.data });
            }
            catch (err) {
                console.log('error getting costings', err);
            }
        },
        successToast(_, msg) {
            useToast().success(msg);
        },
        errorToast(_, msg) {
            useToast().error(msg);
        },
        showAdminHeader({ commit }, value) {
            commit('SHOW_ADMIN_HEADER', { value })
        },
        hideSpinner({ commit }) {
            commit('HIDE_SPINNER');
        },
        showConfirmModal({ commit }, message) {
            return new Promise((resolve) => {
                commit('SHOW_CONFIRM_MODAL', { message, resolve });
            });
        },
        hideConfirmModal({ commit }) {
            commit('HIDE_CONFIRM_MODAL');
        },
        confirmYes({ state, dispatch }) {
            if (state.confirmModal.resolve) {
                state.confirmModal.resolve(true);
                dispatch('hideConfirmModal');
            }
        },
        confirmNo({ state, dispatch }) {
            if (state.confirmModal.resolve) {
                state.confirmModal.resolve(false);
                dispatch('hideConfirmModal');
            }
        }
    },
    modules: {
        auth
    }
});

export default store;