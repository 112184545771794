<template>
  <div ref="counterRef" class="counter"></div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { CountUp } from "countup.js";

export default {
  props: {
    startValue: {
      type: Number,
      required: true,
    },
    endValue: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number, // Duration in seconds
      default: 2,
    },
  },
  setup(props) {
    const counterRef = ref(null);
    let countUpInstance = null;

    const initializeOrUpdateCounter = () => {
      if (counterRef.value) {
        if (countUpInstance) {
          countUpInstance.update(props.endValue);
        } else {
          countUpInstance = new CountUp(counterRef.value, props.endValue, {
            startVal: props.startValue,
            duration: props.duration,
            useEasing: true,
            useGrouping: true,
            separator: ",",
            decimal: ".",
          });
          countUpInstance.start();
        }
      }
    };

    // Initialize counter when component is mounted
    onMounted(() => {
      initializeOrUpdateCounter();
    });

    // Watch for changes in endValue and restart animation
    watch(
      () => props.endValue,
      (newVal) => {
        if (countUpInstance) {
          countUpInstance.update(newVal);
        }
      }
    );

    // Watch for changes in startValue and restart animation
    watch(
      () => props.startValue,
      (newVal) => {
        if (countUpInstance) {
          countUpInstance.update(newVal);
        }
      }
    );

    return {
      counterRef,
    };
  },
};
</script>
