<template>
  <div class="row">
    <div v-if="isProcessing" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div v-if="scanResult.success">
        <div class="card shadow-sm">
          <div class="card-body text-center">
            <i class="bi bi-check-circle text-success" style="font-size: 5rem"></i>
            <h4 class="mt-3 mb-2 text-dark fw-bold">
              {{ scanResult.voter.name_voter?.toUpperCase() }}
            </h4>
            <h5 class="text-muted mb-4">
              {{ scanResult.voter.type_voter?.toUpperCase() }}
            </h5>
            <div class="row mb-3">
              <div class="col-12">
                <p class="fw-bold mb-1">Address:</p>
                <p class="text-muted">
                  {{ scanResult.voter.brgy_voter?.toUpperCase() }}
                </p>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <p class="fw-bold mb-1">Gender:</p>
                <p class="text-muted">
                  {{ scanResult.voter.gender_voter?.toUpperCase() }}
                </p>
              </div>
            </div>
            <button @click.prevent="scanMore" class="btn btn-success btn-lg">
              <i class="bi bi-qr-code"></i> Scan Another
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="scanResult.message !== null" class="alert alert-danger">
          <p class="alert-title">{{ scanResult.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import voterService from "@/services/voterService";
import { ref, watch, onMounted, computed } from "vue";
export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      default: () => "live",
    },
  },
  emits: ["scan-another"],
  setup(props, { emit }) {
    const scanResult = ref({
      success: false,
      message: null,
      voter: {},
    });
    const isProcessing = ref(true);
    const currentCode = computed(() => props.code);
    const scanMore = () => {
      emit("scan-another");
    };

    const fetchData = async () => {
      isProcessing.value = false;
      if (currentCode.value === null) return;
      isProcessing.value = true;
      scanResult.value.message = null;
      scanResult.value = await voterService.getVoterByQrCodeAsync(
        currentCode.value,
        props.mode
      );
      isProcessing.value = false;
      setTimeout(() => {
        emit("scan-another");
      }, 10000);
    };
    watch(currentCode, async (newVal) => {
      if (newVal !== null && newVal !== "") {
        await fetchData();
      }
    });
    onMounted(async () => {
      await fetchData();
    });

    return {
      scanMore,
      scanResult,
      isProcessing,
    };
  },
};
</script>
