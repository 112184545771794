<template>
  <div class="container mt-3">
    <div class="list-group">
      <a
        v-for="(household, index) in households"
        :key="index"
        @click.prevent="handleClick(household)"
        href="#"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ household.name_hh }}</h5>
          <small
            >{{ household.memcount }}
            {{ household.memcount > 1 ? "people" : "person" }}</small
          >
        </div>
        <p class="mb-1">Location: {{ household.brgy_hh }}, {{ household.city_hh }}</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    households: Object,
  },
  emits: ["household-selected"],
  setup(props, { emit }) {
    const handleClick = (household) => {
      emit("household-selected", household);
    };

    return {
      handleClick,
    };
  },
};
</script>

<style>
.list-group-item {
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
