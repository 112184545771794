<template>
  <div class="row my-3"></div>
  <div ref="mapDiv" style="width: 100%; height: 80vh"></div>
  <MediumSizedModal :title="modalTitle" @close="toggleModal" :show="showModal">
    <AreaPicker @polygon-saved="onPolygonSaved" :refreshInvoked="refreshInvoked" />
  </MediumSizedModal>
</template>

<script>
/* eslint-disable no-undef */

import { ref, onMounted, onUnmounted } from "vue";
import areaService from "@/services/areaService";
import { coordinatesToWKT, parseCoordinatesString } from "@/utils/gmapHelper";
import loader from "@/utils/googlemaps-config";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import AreaPicker from "./AreaPicker.vue";
import toastService from "@/services/toastService";
import spinnerService from "@/services/spinnerService";
// eslint-disable-next-line no-unused-vars
const combinations = [
  { fill: "#FF0000", stroke: "#FF4500" },
  { fill: "#FF0000", stroke: "#32CD32" },
  { fill: "#FF0000", stroke: "#00008B" },
  { fill: "#FF0000", stroke: "#FFD700" },
  { fill: "#FF0000", stroke: "#00CED1" },
  { fill: "#FF0000", stroke: "#FF69B4" },
  { fill: "#FF0000", stroke: "#808080" },
  { fill: "#00FF00", stroke: "#FF4500" },
  { fill: "#00FF00", stroke: "#32CD32" },
  { fill: "#00FF00", stroke: "#00008B" },
  { fill: "#00FF00", stroke: "#FFD700" },
  { fill: "#00FF00", stroke: "#00CED1" },
  { fill: "#00FF00", stroke: "#FF69B4" },
  { fill: "#00FF00", stroke: "#808080" },
  { fill: "#0000FF", stroke: "#FF4500" },
  { fill: "#0000FF", stroke: "#32CD32" },
  { fill: "#0000FF", stroke: "#00008B" },
  { fill: "#0000FF", stroke: "#FFD700" },
  { fill: "#0000FF", stroke: "#00CED1" },
  { fill: "#0000FF", stroke: "#FF69B4" },
  { fill: "#0000FF", stroke: "#808080" },
  { fill: "#FFFF00", stroke: "#FF4500" },
  { fill: "#FFFF00", stroke: "#32CD32" },
  { fill: "#FFFF00", stroke: "#00008B" },
  { fill: "#FFFF00", stroke: "#FFD700" },
  { fill: "#FFFF00", stroke: "#00CED1" },
  { fill: "#FFFF00", stroke: "#FF69B4" },
  { fill: "#FFFF00", stroke: "#808080" },
  { fill: "#00FFFF", stroke: "#FF4500" },
  { fill: "#00FFFF", stroke: "#32CD32" },
  { fill: "#00FFFF", stroke: "#00008B" },
  { fill: "#00FFFF", stroke: "#FFD700" },
  { fill: "#00FFFF", stroke: "#00CED1" },
  { fill: "#00FFFF", stroke: "#FF69B4" },
  { fill: "#00FFFF", stroke: "#808080" },
  { fill: "#FF00FF", stroke: "#FF4500" },
  { fill: "#FF00FF", stroke: "#32CD32" },
  { fill: "#FF00FF", stroke: "#00008B" },
  { fill: "#FF00FF", stroke: "#FFD700" },
  { fill: "#FF00FF", stroke: "#00CED1" },
  { fill: "#FF00FF", stroke: "#FF69B4" },
  { fill: "#FF00FF", stroke: "#808080" },
  { fill: "#C0C0C0", stroke: "#FF4500" },
  { fill: "#C0C0C0", stroke: "#32CD32" },
  { fill: "#C0C0C0", stroke: "#00008B" },
  { fill: "#C0C0C0", stroke: "#FFD700" },
  { fill: "#C0C0C0", stroke: "#00CED1" },
  { fill: "#C0C0C0", stroke: "#FF69B4" },
  { fill: "#C0C0C0", stroke: "#808080" },
  { fill: "#FF4500", stroke: "#00FF00" }, // Adding one more unique combination
];
``;

export default {
  components: {
    MediumSizedModal,
    AreaPicker,
  },
  setup() {
    const mapDiv = ref(null);
    const map = ref(null);
    let drawingManager = null;
    let currentPolygon = null;
    let colorIndex = 0;
    const refreshInvoked = ref(false);
    const showModal = ref(false);
    const modalTitle = ref("Create new Polygon");
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    const onPolygonSaved = async (pInfo) => {
      const path = currentPolygon.getPath();
      const coordinates = path.getArray();
      var wkt = coordinatesToWKT(JSON.stringify(coordinates));
      const response = await areaService.savePolygonToDatabase(
        pInfo.id_brgy,
        "brgy",
        wkt
      );
      if (response.success) {
        toastService.success("Polygon saved successfully!");
        toggleModal();
        // currentPolygon.value.id = Number(response.ref);
      } else {
        toastService.error(response.message);
      }
      invokeRefresh();
    };
    const invokeRefresh = () => {
      if (refreshInvoked.value === true) {
        refreshInvoked.value = false;
        setTimeout(() => {
          refreshInvoked.value = true;
        }, 200);
      } else {
        refreshInvoked.value = true;
      }
    };
    onMounted(async () => {
      try {
        await loader.load();
        initializeMap();
      } catch (error) {
        console.error("Error loading Google Maps API:", error);
      }
    });

    const initializeMap = async () => {
      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: 40.7128, lng: -74.006 },
        zoom: 10,
      });

      enablePolygonDrawing();
      handleRightClick(); // Add right-click listener
      // enableHandTool(); // Add hand tool listener
      spinnerService.show();
      await getMunPolygonsFromDatabase();
      await getBrgyPolygonsFromDatabase();
      spinnerService.hide();
    };

    const getMunPolygonsFromDatabase = async () => {
      const muns = await areaService.getMunicipalitiesWithPolygons();
      muns.forEach((mun) => {
        // Example municipality object structure
        // {
        //   name: 'Municipality Name',
        //   polygon: [
        //     { lat: 40.7128, lng: -74.0060 },
        //     { lat: 40.7128, lng: -73.9960 },
        //     { lat: 40.7228, lng: -73.9960 },
        //     { lat: 40.7228, lng: -74.0060 }
        //   ],
        //   editable: true // or false
        // }

        if (mun.area_mun) {
          redrawEditablePolygon(
            map.value,
            mun.id_mun,
            "mun",
            mun.name_mun,
            parseCoordinatesString(mun.area_mun)
          );
          //         addPolygonToMap(municipality.polygon, municipality.editable);
        } else {
          console.warn("No polygon data found for municipality:", municipality);
        }
      });
    };
    const getBrgyPolygonsFromDatabase = async () => {
      const brgys = await areaService.getBrgysWithPolygons();
      brgys.forEach((brgy) => {
        // Example municipality object structure
        // {
        //   name: 'Municipality Name',
        //   polygon: [
        //     { lat: 40.7128, lng: -74.0060 },
        //     { lat: 40.7128, lng: -73.9960 },
        //     { lat: 40.7228, lng: -73.9960 },
        //     { lat: 40.7228, lng: -74.0060 }
        //   ],
        //   editable: true // or false
        // }

        if (brgy.area_brgy) {
          redrawEditablePolygon(
            map.value,
            brgy.id_brgy,
            "brgy",
            brgy.name_brgy,
            parseCoordinatesString(brgy.area_brgy)
          );
          //         addPolygonToMap(municipality.polygon, municipality.editable);
        } else {
          console.warn("No polygon data found for municipality:", mun);
        }
      });
    };

    const redrawEditablePolygon = (map, id, type, name, coordinates) => {
      // Create a new editable polygon
      var fillColor = combinations[colorIndex].fill; //"#FF0000"; //
      var strokeColor = combinations[colorIndex].stroke;
      const polygon = new google.maps.Polygon({
        paths: coordinates,
        map: map,
        fillColor: fillColor, // Set your desired fill color
        strokeColor: strokeColor, // Set your desired stroke color
        editable: true,
        // draggable: true
      });

      // Add a custom property to store the ID
      polygon.id = id;
      polygon.name = name;
      polygon.isModified = false;
      polygon.type = type;
      colorIndex++;
      if (colorIndex >= 49) {
        colorIndex = 0;
      }
      // Optionally, fit the map to the bounds of the polygon
      const bounds = new google.maps.LatLngBounds();
      coordinates.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });
      map.fitBounds(bounds);
      // Create a label for the polygon
      new google.maps.Marker({
        position: bounds.getCenter(),
        map: map,
        label: {
          text: name,
          fontSize: polygon.type == "mun" ? "18px" : "10px",
          fontWeight: "bold",
          color: "#F0F0F0", // Off-White or your preferred color
        },
        icon: {
          url: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=", // Transparent pixel
          size: new google.maps.Size(1, 1), // Set size to 1x1 pixel
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
        },
        optimized: false, // Disable marker optimization
        customProperties: {
          id: id, // Replace with your actual ID
          name: name, // Replace with your actual name
        },
      });

      google.maps.event.addListener(polygon.getPath(), "set_at", handleModification);
      google.maps.event.addListener(polygon.getPath(), "insert_at", handleModification);
      google.maps.event.addListener(polygon, "dragend", handleModification);

      // Function to handle modification state and update the info window content
      async function handleModification() {
        const path = polygon.getPath();
        const coordinates = path.getArray();
        var wkt = coordinatesToWKT(JSON.stringify(coordinates));
        await areaService.savePolygonToDatabase(polygon.id, polygon.type, wkt);
      }

      // Function to send an AJAX request to update the polygon in the database

      // Return the created editable polygon if you need to keep a reference to it
      return polygon;
    };

    const enablePolygonDrawing = () => {
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: false,
        },
      });

      drawingManager.setMap(map.value);

      google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          currentPolygon = event.overlay;
          // console.log("New Polygon:", currentPolygon);
          toggleModal();
          // Add listeners to the new polygon
          //addPolygonEventListeners(currentPolygon);
        }
      });
    };

    /* const addPolygonEventListeners = (polygon) => {
      google.maps.event.addListener(polygon.getPath(), "set_at", () => {
        console.log("Polygon path changed (vertex moved or added)");
      });
      google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
        console.log("Polygon path changed (vertex inserted)");
      });
      google.maps.event.addListener(polygon.getPath(), "remove_at", () => {
        console.log("Polygon path changed (vertex removed)");
      });
    }; */

    const handleRightClick = () => {
      map.value.addListener("rightclick", () => {
        if (currentPolygon) {
          currentPolygon.setMap(null); // Remove the partially drawn polygon
          drawingManager.setDrawingMode(null); // Stop drawing mode
          currentPolygon = null; // Reset currentPolygon
        }
      });
    };
    /*
    const enableHandTool = () => {
      // Add a button or tool to switch to hand tool
      google.maps.event.addListener(map.value, "mousedown", (event) => {
        // This event is used to re-enable map dragging
        if (event.vertex === undefined) {
          // Check if the event is not related to a polygon vertex
          map.value.setOptions({ draggable: true });
        }
      });

      google.maps.event.addListener(map.value, "mousemove", (event) => {
        // Make sure that map dragging is enabled
        console.log(event);
        map.value.setOptions({ draggable: true });
      });

      google.maps.event.addListener(map.value, "mouseup", () => {
        // On mouse up, ensure the map remains draggable
        map.value.setOptions({ draggable: true });
      });
    };
 */
    onUnmounted(() => {
      if (drawingManager) {
        google.maps.event.clearInstanceListeners(drawingManager);
        drawingManager.setMap(null);
        drawingManager = null;
      }
      if (map.value) {
        google.maps.event.clearInstanceListeners(map.value);
      }
    });

    return {
      mapDiv,
      showModal,
      toggleModal,
      modalTitle,
      onPolygonSaved,
      refreshInvoked,
    };
  },
};
</script>

<style scoped>
#map {
  height: 400px;
  width: 100%;
}
</style>
