<template>
  <h3 class="mt-1"><i class="bi bi-house-door-fill me-1"></i>Barangay Settings</h3>
  <BrgysTable />
</template>

<script>
import BrgysTable from "@/components/areas/BrgysTable.vue";

export default {
  components: {
    BrgysTable,
  },
  setup() {},
};
</script>
