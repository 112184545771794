<template>
  <SpinnerOverlay />
  <ConfirmDialog />
  <LiveScanner
    v-if="showAdminHeader"
    :showScanner="showLiveScan"
    :scanMode="scanMode"
    @close="onScannerClosed"
  />
  <div>
    <nav
      v-if="showAdminHeader"
      class="navbar navbar-expand-lg navbar-danger bg-light fixed-top"
    >
      <div class="container-fluid">
        <div class="d-flex justify-content-left align-items-center">
          <button
            @click="toggleSidebar"
            class="btn btn-sm btn-outline-light me-2 mt-1"
            type="button"
          >
            <i class="bi bi-list text-dark fs-3"></i>
          </button>
          <router-link to="/" class="navbar-brand text-decoration-none" href="#">
            <img
              src="/favicon.ico"
              alt="Logo"
              class="d-inline-block align-text-top"
              width="30"
              height="30"
            />
            <span class="d-none d-lg-inline ms-2">Electrack</span>
          </router-link>
        </div>

        <div class="d-flex align-items-center ms-auto">
          <div class="dropdown">
            <a
              title="Account"
              class="dropdown-toggle username d-flex align-items-center me-2"
              href="#"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person-circle me-1 fs-2"></i>
              <span class="d-none d-lg-block">
                {{ currentUser ? currentUser.name_user.toUpperCase() : "Guest" }}
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
              <li>
                <h3 class="dropdown-header text-center">
                  {{ currentUser ? currentUser.fullname_user : "" }}
                </h3>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="toggleLiveScan('live')"
                  v-if="currentUser && hasRequiredRole(['admin', 'web-scan-live'])"
                  class="dropdown-item"
                >
                  <i class="bi bi-qr-code-scan me-1"></i> Live Scan
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="toggleLiveScan('print')"
                  v-if="currentUser && hasRequiredRole(['admin', 'web-scan-print'])"
                  class="dropdown-item"
                >
                  <i class="bi bi-qr-code-scan me-1"></i> ID Print Scan
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a href="#" @click.prevent="logout" class="dropdown-item">
                  <i class="bi bi-box-arrow-left me-1"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="d-flex b-danger" id="content-container">
      <div
        v-if="showAdminHeader"
        style="z-index: 99"
        :class="[
          'bg-light',
          'border-end',
          'sidebar-wrapper',
          { toggled: isSidebarToggled },
        ]"
        id="sidebar-wrapper"
      >
        <div class="list-group list-group-flush">
          <router-link
            to="/"
            class="list-group-item list-group-item-action bg-light mt-3"
          >
            <i class="bi bi-speedometer me-2"></i> Dashboard
          </router-link>
          <div class="accordion" id="shortcutsAccordion">
            <div class="accordion-item">
              <button
                class="accordion-button collapsed bg-light text-decoration-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#shortcutsCollapse"
                aria-expanded="false"
                aria-controls="shortcutsCollapse"
              >
                <i class="bi bi-people me-2"></i> Voters
              </button>
              <div
                id="shortcutsCollapse"
                class="accordion-collapse collapse"
                aria-labelledby="shortcutsCollapse"
                data-bs-parent="#shortcutsAccordion"
              >
                <div
                  class="accordion-body"
                  v-if="currentUser && hasRequiredRole(['admin', 'voters'])"
                >
                  <router-link to="/voters" class="dropdown-item py-2">
                    <i class="bi bi-people-fill me-2"></i> Voters List
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'households'])"
                    to="/households"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-house-door-fill me-2"></i> Households
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'heatmap'])"
                    to="/heatmap"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-geo-alt-fill me-2"></i> Heat Map
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'import'])"
                    to="/imports"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-person-fill-down me-2"></i> Imports
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="settingsAccordion">
            <div class="accordion-item">
              <button
                class="accordion-button collapsed bg-light text-decoration-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#settingsCollapse"
                aria-expanded="false"
                aria-controls="settingsCollapse"
              >
                <i class="bi bi-gear me-2"></i> Settings
              </button>
              <div
                id="settingsCollapse"
                class="accordion-collapse collapse"
                aria-labelledby="settingsCollapse"
                data-bs-parent="#settingsAccordion"
              >
                <div class="accordion-body">
                  <router-link
                    to="/city-setup"
                    v-if="currentUser && hasRequiredRole(['admin', 'city-setup'])"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-buildings-fill me-2"></i> Municipal Settings
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'brgy-setup'])"
                    to="/brgy-setup"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-house-door-fill me-2"></i> Barangay Settings
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'heatmap-setup'])"
                    to="/map-setup"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-gear-fill me-2"></i> Map Settings
                  </router-link>
                  <router-link
                    v-if="currentUser && hasRequiredRole(['admin', 'user-setup'])"
                    to="/user-setup"
                    class="dropdown-item py-2"
                  >
                    <i class="bi bi-person-fill-gear me-2"></i> User Settings
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <router-link
            v-if="currentUser && hasRequiredRole(adminPrintRoles)"
            to="print-id"
            class="list-group-item list-group-item-action bg-light"
          >
            <i class="bi bi-printer me-2"></i> Printing
          </router-link>
          <router-link
            to="/voter-logs"
            class="list-group-item list-group-item-action bg-light"
          >
            <i class="bi bi-calendar-event me-2"></i> Events
          </router-link>
        </div>
      </div>
      <div id="page-content-wrapper" :class="{ toggled: isSidebarToggled }">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount, onMounted, watch } from "vue";
import SpinnerOverlay from "./animations/SpinnerOverlay.vue";
import { getUserRoles } from "@/utils/auth";
import router from "@/router";
import ConfirmDialog from "./animations/ConfirmDialog.vue";
import store from "@/store";
import confirmationService from "@/services/confirmationService";
import { roles } from "@/utils/config";
import LiveScanner from "./qrscanners/LiveScanner.vue";
import signalRService from "@/services/signalRService";

export default {
  components: {
    SpinnerOverlay,
    ConfirmDialog,
    LiveScanner,
  },
  setup() {
    const isSidebarToggled = ref(false);
    const showAdminHeader = ref(store.state.showAdminHeader);
    const adminPrintRoles = computed(() => roles.adminPrint);
    const showLiveScan = ref(false);
    const scanMode = ref("live");

    const onScannerClosed = () => {
      showLiveScan.value = false;
    };

    const toggleLiveScan = (mode) => {
      scanMode.value = mode;
      showLiveScan.value = !showLiveScan.value;
    };

    const hasRequiredRole = (requiredRoles) => {
      return requiredRoles.some((role) => getUserRoles().includes(role));
    };

    const toggleSidebar = () => {
      isSidebarToggled.value = !isSidebarToggled.value;
    };

    const currentUser = computed(() => store.getters["auth/currentUser"] ?? null);

    const logout = async () => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to logout?"
      );
      if (!confirmed) return;
      store.dispatch("showAdminHeader", false);
      await store.dispatch("auth/logout");
      router.push("/login");
      //window.location.replace("/login");
    };
    watch(
      () => store.state.showAdminHeader,
      () => {
        showAdminHeader.value = store.state.showAdminHeader;
      }
    );

    // Manage header visibility based on route meta field
    const updateHeaderVisibility = () => {
      const currentRoute = router.currentRoute.value;
      showAdminHeader.value = currentRoute.meta.showHeader !== false;
    };

    onMounted(() => {
      updateHeaderVisibility();
      // showAdminHeader.value = store.state.showAdminHeader;
    });

    onBeforeUnmount(() => {
      signalRService.connection
        .stop()
        .then(() => {
          console.log("SignalR connection stopped");
        })
        .catch((err) => {
          console.error("Error stopping SignalR connection:", err);
        });
    });

    return {
      isSidebarToggled,
      toggleSidebar,
      showAdminHeader,
      currentUser,
      logout,
      hasRequiredRole,
      adminPrintRoles,
      showLiveScan,
      toggleLiveScan,
      onScannerClosed,
      scanMode,
    };
  },
};
</script>

<style scoped>
#content-container {
  margin-top: 55px;
  height: calc(100vh - 55px);
}

a.username {
  text-decoration: none;
  color: rgb(158, 29, 29);
}

.sidebar-wrapper {
  width: 250px;
  transition: all 0.3s ease;
  height: calc(100vh - 55px);
}

#page-content-wrapper {
  width: 100%;
  padding: 20px;
  transition: all 0.3s ease;
}

.sidebar-wrapper.toggled {
  transform: translateX(-100%);
}

#page-content-wrapper.toggled {
  margin-left: -250px;
}

@media (max-width: 767px) {
  .sidebar-wrapper {
    width: 250px;
    position: fixed;
    transform: translateX(-100%);
  }

  .sidebar-wrapper.toggled {
    transform: translateX(0);
  }

  #page-content-wrapper.toggled {
    margin-left: 0;
  }

  #page-content-wrapper {
    margin-left: 0;
  }
}
</style>
