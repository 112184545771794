<template>
  <div class="text-end d-print-none">
    <button @click="addHousehold" class="btn btn-success">
      <i class="bi bi-plus"></i>
    </button>
  </div>
  <form @submit.prevent="handleSubmit">
    <div class="table-responsive">
      <table class="table align-items-center">
        <thead>
          <tr>
            <th>Name</th>
            <th>City/Mun</th>
            <th>Brgy</th>
            <th>Members</th>
          </tr>
          <tr class="d-print-none">
            <th>
              <input class="form-control" v-model="searchModel.name_hh" />
            </th>
            <th>
              <select
                @change="onCityChanged"
                class="form-select"
                v-model="searchModel.cityid_hh"
              >
                <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
                  {{ city.name_mun }}
                </option>
              </select>
            </th>
            <th>
              <select
                @change="onBrgyChanged"
                class="form-select"
                v-model="searchModel.brgyid_hh"
              >
                <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
                  {{ brgy.name_brgy }}
                </option>
              </select>
            </th>
            <th>
              <input class="form-control" v-model="searchModel.memcount" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="household in households" :key="household.syspk_hh">
            <td>
              <a href="#" @click.prevent="handleEditClick(household)">
                {{ household.name_hh }}
              </a>
            </td>
            <td>{{ household.city_hh }}</td>
            <td>{{ household.brgy_hh }}</td>
            <td>{{ household.memcount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationControl
      v-if="households !== null && households.length !== 0"
      @page-change="handleChange"
      :page="searchModel.currentPage"
      :pages="searchModel.totalPages"
      :items="searchModel.totalItems"
    />
    <button type="submit" class="btn btn-primary d-none">Search</button>
  </form>
  <FullscreenModal title="Household Information" @close="toggleModal" :show="showModal">
    <HouseholdInfo :id="currentId" @prev="prev" @next="next" />
  </FullscreenModal>
</template>

<script>
import { onMounted, ref } from "vue";
import householdService from "@/services/householdService";
import areaService from "@/services/areaService";
import FullscreenModal from "../modals/FullscreenModal.vue";
import HouseholdInfo from "./HouseholdInfo.vue";
import spinnerService from "@/services/spinnerService";
import PaginationControl from "../PaginationControl.vue";
import toastService from "@/services/toastService";

export default {
  components: {
    FullscreenModal,
    HouseholdInfo,
    PaginationControl,
  },
  setup() {
    const showModal = ref(false);
    const currentId = ref(null);
    const households = ref([]);
    const searchModel = ref({ totalPages: 0, currentPage: 1, totalItems: 0 });
    const cities = ref([]);
    const brgys = ref([]);
    const paginationSearchModel = ref({});
    const searchHouseholds = async () => {
      spinnerService.show();
      searchModel.value.currentPage = 1;
      paginationSearchModel.value = { ...searchModel.value };
      const response = await householdService.search(searchModel.value);
      households.value = response.households;
      searchModel.value.totalItems = response.totalItems;
      searchModel.value.totalPages = response.totalPages;
      searchModel.value.currentPage = response.currentPage;
      spinnerService.hide();
    };
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const getPresets = async () => {
      cities.value = await areaService.getMunicipalities();
      if (cities.value.length > 0) {
        searchModel.value.cityid_hh = cities.value[0].id_mun;
        brgys.value = await areaService.getBrgys(searchModel.value.cityid_hh);
      }
    };
    const onCityChanged = async () => {
      brgys.value = await areaService.getBrgys(searchModel.value.cityid_hh);
    };
    const onBrgyChanged = () => {
      searchHouseholds();
    };
    const handleEditClick = (household) => {
      currentId.value = household.syspk_hh;
      toggleModal();
    };
    const addHousehold = () => {
      currentId.value = null;
      toggleModal();
    };

    const prev = () => {
      let idx = households.value.findIndex((x) => x.syspk_hh == currentId.value);
      if (idx > 0) {
        currentId.value = households.value[idx - 1].syspk_hh;
      }
    };

    const next = () => {
      let idx = households.value.findIndex((x) => x.syspk_hh == currentId.value);
      if (idx < households.value.length - 1) {
        currentId.value = households.value[idx + 1].syspk_hh;
      }
    };
    const handleSubmit = () => {
      searchHouseholds();
    };

    const handleChange = async (page) => {
      try {
        spinnerService.show();
        searchModel.value.currentPage = page;
        paginationSearchModel.value.currentPage = page;
        const response = await householdService.search(paginationSearchModel.value);
        searchModel.value.totalPages = response.totalPages;
        searchModel.value.totalItems = response.totalItems;
        households.value = response.households;
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    onMounted(() => {
      getPresets();
      searchHouseholds();
    });

    return {
      households,
      searchModel,
      cities,
      brgys,
      onCityChanged,
      onBrgyChanged,
      handleEditClick,
      showModal,
      toggleModal,
      currentId,
      addHousehold,
      next,
      prev,
      handleSubmit,
      handleChange,
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
