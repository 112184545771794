<template>

    <div v-if="!isProcessing" class="table-responsive" style="overflow-y: auto; max-height: 300px;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>

                <tr v-if="leader !== null && leader !== ''">
                    <td class="bg-danger text-light">{{ leader }}</td>
                    <td class="bg-danger text-light">Leader</td>
                </tr>

                <tr v-for="voter, index in groupings" :key="index">
                    <td
                        :class="[{ 'bg-danger': voter.type_voter === 'LEADER' }, { 'bg-warning': voter.type_voter === 'SUB-LEADER' }]">
                        {{ voter.name_voter }}</td>
                    <td
                        :class="[{ 'bg-danger': voter.type_voter === 'LEADER' }, { 'bg-warning': voter.type_voter === 'SUB-LEADER' }]">
                        {{ voter.type_voter }}</td>
                </tr>

                <tr v-if="groupings === null || groupings.length === 0">
                    <td colspan="3">
                        <div class="alert alert-info text-center">Not assigned to group.
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div v-else class="text-center vh-100">
        <div class="spinner-border text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
import voterService from '@/services/voterService';
import { computed, ref, watch } from 'vue';

export default {
    props: {
        id: Number,
        leader: String
    },
    setup(props) {
        const groupings = ref([]);
        const isProcessing = ref(false);
        const currentId = computed(() => props.id);
        const getVoterGroups = async () => {
            isProcessing.value = true;
            const response = await voterService.getVoterGroups(currentId.value);
            groupings.value = response;
            isProcessing.value = false;
        };
        watch(currentId, (newValue, oldValue) => {
            if (newValue !== oldValue)
                getVoterGroups();
        });
        return {
            groupings,
            isProcessing,
        }
    }
}
</script>
