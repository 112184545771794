<!-- components/SpinnerOverlay.vue -->
<template>
  <div v-if="showSpinner" class="spinner-overlay">
    <div class="spinner"></div>
  </div>
</template>

<script>
import router from "@/router";
import { computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "SpinnerOverlay",
  setup() {
    const store = useStore();
    const showSpinner = computed(() => store.state.showSpinner);
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    watch(currentUser, () => {
      if (currentUser.value === null) {
        router.replace({ name: "login" });
      }
    });
    return {
      showSpinner,
    };
  },
};
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
