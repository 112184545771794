import apiService from "./apiService";

const householdService = {
    async search(searchModel) {
        try {
            return (await apiService.get(`h/search`, searchModel, true)).data;
        }
        catch {
            return [];
        }
    },
    async getInfo(id) {
        try {
            return (await apiService.get(`h/getinfo/${id}`, {}, true)).data;
        }
        catch {
            return {};
        }
    },
    async removeVoterFromHousehold(id) {
        try {
            return (await apiService.get(`h/removefromhousehold/${id}`, {}, true)).data;
        }
        catch {
            return { success: false, message: "An error occured while processing the request." };
        }
    },
    async getNoHouseholdVoters(cityid,brgyid,keyword){
        if(!cityid || !brgyid)
            return;
        try {
            return (await apiService.get(`h/getnohouseholdvoter/${cityid}/${brgyid}`, {keyword: keyword}, true)).data;
        }
        catch {
            return [];
        }
    },
    async addvotertohousehold(id,hh){
        try {
            return (await apiService.post(`h/addvotertohousehold/${id}/${hh}`, {}, true)).data;
        }
        catch {
            return { success: false, message: "An error occured while processing the request." };
        }
    },
    async save(household){
        try{
            return (await apiService.post("h/savehousehold",household,true)).data;
        }catch{
            return {success: false, message: "An error occured while processing the request."}
        }
    }
};

export default householdService;