<template>
  <div class="row">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>City/Municipality</label>
        <select
          required
          @change="handleCityChange"
          v-model="polygonInfo.id_mun"
          class="form-select"
        >
          <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
            {{ city.name_mun }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Brgy</label>
        <select required v-model="polygonInfo.id_brgy" class="form-select">
          <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
            {{ brgy.name_brgy }}
          </option>
        </select>
      </div>
      <div class="form-group mt-3">
        <button type="submit" class="btn btn-success w-100">Save Changes</button>
      </div>
    </form>
  </div>
</template>
<script>
import areaService from "@/services/areaService";
import toastService from "@/services/toastService";
import { onMounted, ref, watch } from "vue";

export default {
  props: {
    refreshInvoked: Boolean,
  },
  emits: ["polygon-saved"],
  setup(props, { emit }) {
    const polygonInfo = ref({ id_mun: 0, id_brgy: 0 });
    const cities = ref([]);
    const brgys = ref([]);
    const getPresets = async () => {
      cities.value = await areaService.getMunicipalities();
      brgys.value = [];
    };

    const handleCityChange = async () => {
      brgys.value = await areaService.getBrgysWithNoPolygon(polygonInfo.value.id_mun);
    };

    const handleSubmit = () => {
      if (polygonInfo.value.id_mun === 0 || polygonInfo.value.id_brgy === 0) {
        toastService.error("Please select valid values.");
        return;
      }
      emit("polygon-saved", polygonInfo.value);
    };
    onMounted(() => {
      getPresets();
    });

    watch(
      () => props.refreshInvoked,
      () => {
        getPresets();
      }
    );

    return {
      handleCityChange,
      handleSubmit,
      cities,
      brgys,
      polygonInfo,
    };
  },
};
</script>
