<template>
  <div class="col-md-12 mb-4">
    <div class="card border-light shadow-sm">
      <div class="card-body">
        <h5 class="card-title mb-4 text-primary">Trail Logs</h5>
        <div v-if="logs.length === 0" class="text-muted text-center">
          No logs available.
        </div>
        <ul v-else class="list-unstyled">
          <li
            v-for="(log, index) in logs"
            :key="index"
            class="mb-3 border p-3 rounded-lg shadow-sm bg-light d-flex justify-content-between align-items-start"
          >
            <div class="log-content text-dark flex-grow-1">
              <div v-html="log.header_voteractlog" class="font-weight-bold mb-2"></div>
              <div class="text-muted small">
                {{ formatRelativeTime(log.created_voteractlog) }}
              </div>
            </div>
            <a
              :href="`/log-details/${index}`"
              class="btn btn-outline-secondary btn-sm"
              @click="handleLogClick(index)"
            >
              <i class="bi bi-info-circle"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, onMounted } from "vue";
import { formatDistanceToNow } from "date-fns";
import signalRService from "@/services/signalRService";
import userService from "@/services/userService";

export default {
  setup() {
    // Sample logs data
    const logs = ref([]);

    // Function to format relative time
    const formatRelativeTime = (timestamp) => {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };

    // Handle log click event
    const handleLogClick = (index) => {
      // Optional: Add any specific actions on click
      console.log(`Viewing details for log index: ${index}`);
    };
    const handleLogChange = () => {
      getRecenctLogs();
    };

    const getRecenctLogs = async () => {
      logs.value = await userService.getRecentLogs();
    };

    onMounted(() => {
      signalRService.subscribeToEvent("NewLogsAdded", handleLogChange);
      getRecenctLogs();
    });
    onUnmounted(() => {
      signalRService.connection.off("NewLogsAdded", handleLogChange);
    });
    return {
      logs,
      formatRelativeTime,
      handleLogClick,
    };
  },
};
</script>

<style>
a.lnk-voter-log {
  text-decoration: none;
  color: black;
}
.log-content {
  line-height: 1.5;
}
</style>
