<template>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isProcessing">
          <td colspan="4" class="text-center">
            <div class="spinner-container">
              <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="voter in statvoters" :key="voter.syspk_voter">
          <td>{{ voter.name_voter }}</td>
          <td>{{ voter.type_voter }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <PaginationControl
    :page="p.currentPage"
    :items="p.totalItems"
    :pages="p.totalPages"
    @page-change="onPageChanged"
  />
</template>

<script>
import { onMounted, ref, watch } from "vue";
import PaginationControl from "../PaginationControl.vue";
import dashboardService from "@/services/dashboardService";

export default {
  components: {
    PaginationControl,
  },
  props: {
    searchparams: { type: Object, default: () => ({ id: 0, state: null }) },
  },
  setup(props) {
    const p = ref({ totalItems: 0, totalPages: 1, currentPage: 1 });
    const statvoters = ref([]);
    const isProcessing = ref(false);
    const fetchData = async (page) => {
      if (props.searchparams.id === 0 || props.searchparams.state === null) return;
      isProcessing.value = true;
      const response = await dashboardService.getDashboardVoterByType(
        props.searchparams.id,
        props.searchparams.state,
        page
      );
      statvoters.value = response.voters;
      p.value.currentPage = page;
      p.value.totalItems = response.totalItems;
      p.value.totalPages = response.totalPages;
      isProcessing.value = false;
    };

    const onPageChanged = (page) => {
      p.value.currentPage = page;
      fetchData(page);
    };
    onMounted(() => {
      fetchData(1);
    });
    watch(
      () => props.searchparams,
      () => {
        fetchData(1);
      },
      { deep: true }
    );
    return {
      onPageChanged,
      statvoters,
      p,
      isProcessing,
    };
  },
};
</script>
