<template>
  <div class="tabs">
    <ul class="tab-header">
      <li
        v-for="title in tabTitles"
        :key="title"
        @click="handleTabHeaderClick(title)"
        :class="{ active: selectedTitle === title }"
      >
        {{ title }}
      </li>
    </ul>
    <slot />
  </div>
</template>
<script>
import { ref, provide } from "vue";
export default {
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);
    provide("selectedTitle", selectedTitle);
    const handleTabHeaderClick = (title) => {
      selectedTitle.value = title;
    };
    return {
      tabTitles,
      selectedTitle,
      handleTabHeaderClick,
    };
  },
};
</script>

<style scoped>
/* Container for the whole tab system */
.tabs {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* The tab header container */
.tab-header {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

/* Style for each tab */
.tab-header li {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  color: #606060; /* Default text color */
  font-size: 14px;
  transition: color 0.2s ease-in-out;
  border-bottom: 2px solid transparent; /* Transparent border to set space */
}

/* Hover effect for tabs */
.tab-header li:hover {
  color: #111; /* Darker text color on hover */
}

/* Active tab styling */
.tab-header li.active {
  color: #000; /* Darker color for the active tab */
  border-bottom: 3px solid #4e4a4a; /* Thick red border below the tab */
}

/* Content area for the tab */

/* Optional: Ensure content area stretches across full width */
.tabs .tab-content {
  width: 100%;
}
</style>
