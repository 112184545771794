import {decryptData} from '@/utils/crypto'

export function isTokenExpired(expiresIn) {
    if (!expiresIn || typeof expiresIn !== 'number') return true;

    const currentTime = Math.floor(new Date().getTime() / 1000); // Current time in seconds
    checkTokenExpiration(expiresIn);
    return expiresIn < currentTime;
}

const checkTokenExpiration = (expiresIn) => {
  const currentTime = Math.floor(new Date().getTime() / 1000); // Current time in seconds since Unix epoch
  const timeDifference = expiresIn - currentTime; // Difference in seconds
  const minutesLeft = Math.floor(timeDifference / 60); // Convert seconds to minutes

  //console.log('Token expires in:', minutesLeft, 'minutes');

  // Return the minutes left
  return minutesLeft;
};
export function isAuthenticated() {
    const token = localStorage.getItem('user'); // Example: Check if a token exists in localStorage
    return !!token; // Return true if token exists, false otherwise
  }

  export function getUserRoles() {
    const rolesString = JSON.parse(decryptData(localStorage.getItem('user'))).roles; // Example: Retrieve roles from localStorage
    return rolesString ? rolesString : []; // Parse roles from localStorage (assuming stored as JSON)
  }

  export function hasRoles(requiredRoles){
    return requiredRoles.some((role) => getUserRoles().includes(role));
  }