<template>
  <MediumSizedModal :show="showModal" title="Scan QR Code" @close="toggleModal">
    <div v-if="scanning">
      <h5 class="text-center">{{ scanMode.toUpperCase() }}</h5>
      <video ref="videoElement" width="100%" height="auto" autoplay></video>
      <canvas ref="canvas" style="display: none"></canvas>
      <div class="text-center" v-if="scanning">Scanning...</div>
    </div>
    <div v-else>
      <VoterScanResult
        v-if="showScanner"
        :code="qrResult"
        :mode="scanMode"
        @scan-another="scanAnother"
      />
    </div>
  </MediumSizedModal>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { BrowserQRCodeReader } from "@zxing/library";
import VoterScanResult from "./VoterScanResult.vue";
import MediumSizedModal from "../modals/MediumSizedModal.vue";
import store from "@/store";
export default {
  components: {
    VoterScanResult,
    MediumSizedModal,
  },
  props: {
    showScanner: Boolean,
    scanMode: { type: String, required: true, default: () => "live" },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const scanning = ref(true);
    const qrResult = ref(null);
    const codeReader = new BrowserQRCodeReader();
    const videoElement = ref(null);
    const showModal = ref(false);
    const toggleModal = () => {
      showModal.value = !showModal.value;
      if (showModal.value === false) {
        emit("close");
        stopScanner();
      }
    };

    let mediaStream = null;

    const startScanner = async () => {
      try {
        const constraints = { video: { facingMode: "environment" } }; // Use the rear camera
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        videoElement.value.srcObject = mediaStream;
        videoElement.value.setAttribute("playsinline", true); // Required to work on iOS Safari

        codeReader.decodeFromVideoDevice(undefined, videoElement.value, (result, err) => {
          if (result) {
            qrResult.value = result.text;
            scanning.value = false; // Stop scanning once a QR code is detected
            stopScanner(); // Stop the scanner
          }
          if (err) {
            console.error(err);
          }
        });

        console.log("QR Code decoding started successfully.");
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    };

    const stopScanner = () => {
      if (codeReader) {
        codeReader.reset(); // Stop the QR code reader
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop()); // Stop all media stream tracks
        mediaStream = null;
      }
      scanning.value = false;
    };
    const scanAnother = () => {
      qrResult.value = null;
      scanning.value = true;
      startScanner();
    };

    watch(
      () => props.showScanner,
      (newVal) => {
        if (newVal === true) {
          qrResult.value = null;
          scanning.value = true;
          startScanner();
          if (showModal.value === false) {
            toggleModal();
          }
        } else {
          stopScanner();
        }
      }
    );

    onMounted(() => {
      if (store.getters["showHeader"]) startScanner();
    });

    onBeforeUnmount(() => {
      stopScanner(); // Stop the scanner when the component is destroyed
    });
    return {
      scanning,
      qrResult,
      videoElement,
      scanAnother,
      showModal,
      toggleModal,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
